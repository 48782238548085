import { MarkerType } from "reactflow";

export const initialNodes = [
  // Start Node
  {
    id: "1",
    type: "default",
    data: {
      label: "Start",
      description: "The initial state of the process where all systems are initialized.",
      inputs: [
        { type: "BOOL", name: "SYSTEM_READY", defaultValue: "false", connectTo: "" },
      ],
      outputs: [
        { type: "BOOL", name: "PROCESS_START", defaultValue: "false", connectTo: "Welding.erd" },
      ],
      programVars: [],
      skills: [],
    },
    position: { x: 100, y: -100 },
    hidden: false,
  },

  // Task Nodes in Sequence
  {
    id: "2",
    type: "taskNode",
    data: {
      fileName: "Welding.erd",
      description: "Perform the welding process to join metal components. Welding starts when materials are positioned correctly, and the WELD_START signal is received.",
      inputs: [
        { type: "BOOL", name: "WELD_START", defaultValue: "false", connectTo: "Start" },
        { type: "BOOL", name: "MATERIAL_POSITIONED", defaultValue: "false", connectTo: "Start" },
      ],
      outputs: [
        { type: "BOOL", name: "WELD_COMPLETE", defaultValue: "false", connectTo: "Surface.erd" },
        { type: "INT", name: "WELD_STATUS", defaultValue: "0", connectTo: "Surface.erd" },
      ],
      programVars: [
        { type: "REAL", name: "WELD_SPEED", defaultValue: "1.0" },
      ],
      skills: ["Welding", "Metal Fabrication"],
    },
    position: { x: 500, y: -300 },
    hidden: false,
  },
  {
    id: "3",
    type: "taskNode",
    data: {
      fileName: "Surface.erd",
      description: "Apply a paint coat to the welded component. The process starts when the surface is ready and paint is loaded. Ensure uniform paint application.",
      inputs: [
        { type: "BOOL", name: "SURFACE_READY", defaultValue: "false", connectTo: "Welding.erd" },
        { type: "BOOL", name: "PAINT_LOADED", defaultValue: "false", connectTo: "Safety.erd" },
      ],
      outputs: [
        { type: "BOOL", name: "PAINT_COMPLETE", defaultValue: "false", connectTo: "Assembly.erd" },
        { type: "INT", name: "PAINT_QUALITY", defaultValue: "0", connectTo: "Assembly.erd" },
      ],
      programVars: [
        { type: "REAL", name: "PAINT_THICKNESS", defaultValue: "0.5" },
      ],
      skills: ["Painting", "Surface Treatment"],
    },
    position: { x: 1000, y: -300 },
    hidden: false,
  },
  {
    id: "4",
    type: "taskNode",
    data: {
      fileName: "Assembly.erd",
      description: "Assemble all parts into the final product. The process starts when parts are ready, and components are loaded.",
      inputs: [
        { type: "BOOL", name: "ASSEMBLY_READY", defaultValue: "false", connectTo: "Surface.erd" },
        { type: "BOOL", name: "COMPONENTS_LOADED", defaultValue: "false", connectTo: "Surface.erd" },
      ],
      outputs: [
        { type: "BOOL", name: "ASSEMBLY_COMPLETE", defaultValue: "false", connectTo: "Safety.erd" },
        { type: "INT", name: "PRODUCT_STATUS", defaultValue: "0", connectTo: "Safety.erd" },
      ],
      programVars: [
        { type: "REAL", name: "ASSEMBLY_TORQUE", defaultValue: "10.0" },
      ],
      skills: ["Assembly", "Mechanical Engineering"],
    },
    position: { x: 1500, y: -300 },
    hidden: false,
  },

  // Parallel Task Node for Safety
  {
    id: "5",
    type: "taskNode",
    data: {
      fileName: "Safety.erd",
      description: "Ensure safety protocols are followed. Verify equipment safety and handle any manual override scenarios.",
      inputs: [
        { type: "BOOL", name: "SAFETY_CHECK", defaultValue: "false", connectTo: "Assembly.erd" },
        { type: "BOOL", name: "SAFETY_MANUAL_OVERRIDE", defaultValue: "false", connectTo: "Assembly.erd" },
      ],
      outputs: [
        { type: "INT", name: "SAFETY_STATUS", defaultValue: "0", connectTo: "" },
        { type: "BOOL", name: "SAFETY_COMPLETE", defaultValue: "false", connectTo: "" },
      ],
      programVars: [
        { type: "REAL", name: "SAFETY_TIMEOUT", defaultValue: "5.0" },
      ],
      skills: ["Safety Management", "Risk Assessment"],
    },
    position: { x: 700, y: 100 },
    hidden: false,
  },
];

export const initialEdges = [
  {
    id: "e1-2",
    source: "1",
    target: "2",
    animated: false,
    type: "smoothstep",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: "#000",
    },
  },
  {
    id: "e2-3",
    source: "2",
    target: "3",
    animated: false,
    type: "smoothstep",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: "#000",
    },
  },
  {
    id: "e3-4",
    source: "3",
    target: "4",
    animated: false,
    type: "smoothstep",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: "#000",
    },
  },
  {
    id: "e1-5",
    source: "1",
    target: "5",
    animated: false,
    type: "smoothstep",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: "#000",
    },
  },
];