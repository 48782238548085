import React from "react";
import { motion } from "framer-motion";
import { Box, Input, Flex, Wrap, WrapItem, Button } from "@chakra-ui/react";

const InitialMessagePrompt = ({ letters, userMessage, setUserMessage, handleSendMessage, isLoading, predefinedQuestions }) => {
  return (
    <Flex height="90vh" justify="center" align="center" flexDirection="column" mt={4} bg="vscode.sidebar" width={"90%"} borderRadius={"xl"}>
      <motion.div initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
        <Box
          textAlign="center"
          mb={4}
          fontSize="xl"
          fontWeight="bold"
          color="white"
        >
          {letters.map((letter, index) => (
            <motion.span
              key={index}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.05,
                delay: index * 0.05,
              }}
            >
              {letter}
            </motion.span>
          ))}
        </Box>
      </motion.div>
      <Flex width="80%" mb={4}>
        <Input
          placeholder="Type your message..."
          value={userMessage}
          onChange={(e) => setUserMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !isLoading) handleSendMessage();
          }}
          isDisabled={isLoading}
          textAlign="center"
          flex="1"
        />
        <Button
          ml={2}
          bg="xelerit.lightBlue"
          color="white"
          onClick={() => handleSendMessage()}
          isLoading={isLoading}
        >
          Send
        </Button>
      </Flex>
      <Wrap spacing="10px" justify="center" width={"80%"}>
        {predefinedQuestions.map((question, index) => (
          <WrapItem key={index}>
            <Button
              size="sm"
              variant={"outline"}
              borderColor="gray.400"
              borderRadius="2xl"
              color="gray.500"
              onClick={() => handleSendMessage(question)}
              isDisabled={isLoading}
              _hover={{ bg: "xelerit.lightBlue", color: "white" }}
            >
              {question}
            </Button>
          </WrapItem>
        ))}
      </Wrap>
    </Flex>
  );
};

export default InitialMessagePrompt;