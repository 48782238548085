import React from 'react';
import { Box, Container, Heading, Text, Link } from '@chakra-ui/react';

const Privacy = () => {
  return (
    <Box as="main" py={10}>
      <Container maxW="container.xl">
        <Heading mb={6}>Privacy Policy</Heading>
        <Text mb={4}>
          Xelerit-Robotics (hereinafter referred to as "Xelerit") is committed to safeguarding the privacy of its users. This Privacy Policy outlines how Xelerit collects, processes, and protects personal data, in compliance with the Swiss Federal Data Protection Act (LPD) and the European General Data Protection Regulation (GDPR). It applies to users based in Switzerland and the European Union, in accordance with Article 3 of the GDPR (territorial scope). By continuing to use our website or services, you agree to the terms of this Privacy Policy.
        </Text>

        <Heading as="h3" size="md" mt={8} mb={4}>
          Scope of Application
        </Heading>
        <Text mb={4}>
          This Privacy Policy applies to the processing of your personal data when you:
        </Text>
        <Text mb={4}>
          - Visit our website.<br />
          - Purchase our services or use the platform's features.<br />
          - Communicate with us regarding contracts, offers, newsletters, or other services.<br />
          - Provide information via registration forms or contact us for specific inquiries.<br />
        </Text>
        <Text mb={4}>
          Xelerit processes only the personal data strictly necessary to deliver and improve the requested services.
        </Text>

        <Heading as="h3" size="md" mt={8} mb={4}>
          Data We Collect
        </Heading>
        <Text mb={4}>
          We collect two types of personal data:
        </Text>
        <Text mb={4}>
          <strong>a. Data Provided by Users</strong><br />
          - Name, address, email address, phone number, and company name.<br />
          - Inputs provided on the platform to access our services, such as production line specifications, project designs, hardware details (brand and model), and simulation tuning information.<br />
        </Text>
        <Text mb={4}>
          <strong>b. Data Collected Automatically</strong><br />
          - IP address, browser type, date, and duration of access.<br />
          - Code templates, flow diagrams, and robot variable mappings generated during service use.<br />
          - Cookie and tracking data (see Cookies and Tracking Technologies section).<br />
        </Text>
        <Text mb={4}>
          Note: Xelerit does not collect or process sensitive data as defined by Article 9 of the GDPR (e.g., health, religion, or ethnic origin).
        </Text>

        <Heading as="h3" size="md" mt={8} mb={4}>
          How We Use Your Data
        </Heading>
        <Text mb={4}>
          Xelerit processes your personal data for the following purposes:
        </Text>
        <Text mb={4}>
          - Service provision: Deliver and enhance platform features, including:<br />
            - Extracting requirements.<br />
            - Mapping robot variables.<br />
            - Generating flow diagrams.<br />
            - Generating robot-ready executable code.<br />
          - Communication: Respond to inquiries, provide support, and manage contracts.<br />
          - Security and fraud prevention: Prevent fraudulent activities, when noticed.<br />
          - Statistical Analysis: Improve user experience through anonymized data analysis.<br />
        </Text>
        <Text mb={4}>
          Inputs and data provided for generating executable code are processed solely to fulfill the specific request. However, anonymized data may be retained for internal purposes, such as improving algorithms and AI training. Xelerit ensures that anonymized data cannot be linked to any user or company and is never shared with third parties.
        </Text>

        <Heading as="h3" size="md" mt={8} mb={4}>
          Cookies and Tracking Technologies
        </Heading>
        <Text mb={4}>
          Xelerit uses cookies and similar technologies to:
        </Text>
        <Text mb={4}>
          - Personalize content.<br />
          - Save user preferences.<br />
          - Collect statistical data on website usage and performance.<br />
        </Text>
        <Text mb={4}>
          Users can manage their cookie preferences via the consent banner displayed upon accessing the website, in compliance with GDPR regulations.
        </Text>

        <Heading as="h3" size="md" mt={8} mb={4}>
          User Access and Management
        </Heading>
        <Text mb={4}>
          Client companies are fully responsible for managing access to the platform, including the registration, monitoring, and revocation of access for their employees or collaborators. It is the client’s responsibility to ensure the confidentiality of user credentials.
        </Text>
        <Text mb={4}>
          Users can exercise their rights (access, rectification, deletion, restriction, portability) by submitting a request to{" "}
          <Link href="mailto:care@xelerit-robotics.com" color="blue.500">
            care@xelerit-robotics.com
          </Link>, in accordance with applicable laws. Xelerit protects transmitted data but does not offer specific guarantees of security or compliance with standards (e.g., ISO).
        </Text>

        <Heading as="h3" size="md" mt={8} mb={4}>
          Data Transmission
        </Heading>
        <Text mb={4}>
          Xelerit may transfer personal data outside Switzerland and the EEA, ensuring adequate protections through data transfer agreements, including standard contractual clauses compliant with applicable laws.
        </Text>

        <Heading as="h3" size="md" mt={8} mb={4}>
          Data Retention
        </Heading>
        <Text mb={4}>
          Personal data is retained for the following durations:
        </Text>
        <Text mb={4}>
          - The period necessary to provide the requested service or maintain the contractual relationship.<br />
          - As required by legal obligations (e.g., tax, accounting purposes).<br />
        </Text>
        <Text mb={4}>
          Data is deleted or anonymized at the end of the retention period.
        </Text>

        <Heading as="h3" size="md" mt={8} mb={4}>
          Data Security
        </Heading>
        <Text mb={4}>
          Xelerit implements measures to protect data from unauthorized access, loss, or manipulation but cannot guarantee the complete elimination of all risks.
        </Text>

        <Heading as="h3" size="md" mt={8} mb={4}>
          Contact
        </Heading>
        <Text mb={4}>
          For any inquiries or concerns, please contact us at:{" "}
          <Link href="mailto:care@xelerit-robotics.com" color="blue.500">
            care@xelerit-robotics.com
          </Link>.
        </Text>
      </Container>
    </Box>
  );
};

export default Privacy;
