import React from 'react';
import {
  Box,
  Flex,
  Heading,
  VStack,
  Text,
  IconButton,
  Collapse,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  useDisclosure,
  Textarea,
} from '@chakra-ui/react';
import { FaUpload, FaAngleDown, FaTrash, FaAngleRight, FaEdit, FaSave } from 'react-icons/fa';
import { processUploadFile } from '../../api/requirements';

const TextRequirementsPanel = ({ data, onUpdate }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [file, setFile] = React.useState(null);
  const [isUploading, setIsUploading] = React.useState(false);

  // Local state mirrors the passed data for editing
  const [response, setResponse] = React.useState(data);

  // States for collapsible sections
  const [showShortTasks, setShowShortTasks] = React.useState(false);
  const [showSequences, setShowSequences] = React.useState(false);
  const [showMainFunctions, setShowMainFunctions] = React.useState(false);

  const [editCategory, setEditCategory] = React.useState(null);
  const [editIndex, setEditIndex] = React.useState(null);
  const [editValue, setEditValue] = React.useState('');

  React.useEffect(() => {
    setResponse(data); // Sync local state with props when data changes
  }, [data]);


  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (file) {
      setIsUploading(true); // Set loading state to true
      console.log("Uploading file:", file);
      try {
        const uploadedData = await processUploadFile(file);
        console.log("API response structure:", JSON.stringify(uploadedData, null, 2));
        if (typeof uploadedData.response === "string") {
          const parsedData = JSON.parse(uploadedData.response);
          const { shortTaskDescription, sequences, mainFunctions } = parsedData;

          const updatedData = { shortTaskDescription, sequences, mainFunctions };
          setResponse(updatedData);
          onUpdate(updatedData); // Pass updated data to Home
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setIsUploading(false);
        onClose();
      }
    }
  };

  const handleEdit = (category, index, value) => {
    setEditCategory(category);
    setEditIndex(index);
    setEditValue(value);
  };

  const handleSaveEdit = () => {
    const updated = { ...response };
    if (editCategory === 'shortTaskDescription') {
      updated.shortTaskDescription[editIndex] = editValue;
    } else if (editCategory === 'sequences') {
      updated.sequences[editIndex] = editValue;
    } else if (editCategory === 'mainProgram') {
      updated.mainFunctions.mainProgram[editIndex] = editValue;
    } else if (editCategory === 'subFunctions') {
      updated.mainFunctions.subFunctions[editIndex] = editValue;
    }
    setResponse(updated);
    onUpdate(updated); // Update the parent component
    setEditCategory(null);
    setEditIndex(null);
    setEditValue('');
  };

  const handleAddItem = (category, value) => {
    const updated = { ...response };
    if (category === "shortTaskDescription") {
      updated.shortTaskDescription.push(value);
    } else if (category === "sequences") {
      updated.sequences.push(value);
    } else if (category === "mainProgram") {
      updated.mainFunctions.mainProgram.push(value);
    } else if (category === "subFunctions") {
      updated.mainFunctions.subFunctions.push(value);
    }
    setResponse(updated);
    onUpdate(updated); // Update the parent component
  };

  const handleRemoveItem = (category, index) => {
    const updated = { ...response };
    if (category === "shortTaskDescription") {
      updated.shortTaskDescription.splice(index, 1);
    } else if (category === "sequences") {
      updated.sequences.splice(index, 1);
    } else if (category === "mainProgram") {
      updated.mainFunctions.mainProgram.splice(index, 1);
    } else if (category === "subFunctions") {
      updated.mainFunctions.subFunctions.splice(index, 1);
    }
    setResponse(updated);
    onUpdate(updated); // Update the parent component
  };
  
  

  const renderItems = (items, category) =>
    items.map((item, index) => (
      <Flex
        key={index}
        align="center"
        justify="space-between"
        px={1}
        py={0.5}
        gap={1}
      >
        {editCategory === category && editIndex === index ? (
          <Input
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            size="xs"
            bg="white"
            color="black"
            fontSize="xs"
            px={1}
            width="400px"
          />
        ) : (
          <Text textStyle="xs" fontSize="xs" noOfLines={1}>
            {item}
          </Text>
        )}
        <Flex gap={1}>
          {editCategory === category && editIndex === index ? (
            <IconButton
              aria-label="Save"
              icon={<FaSave />}
              size="xs"
              colorScheme="green"
              onClick={handleSaveEdit}
            />
          ) : (
            <IconButton
              aria-label="Edit"
              icon={<FaEdit />}
              size="xs"
              bg="xelerit.lightBlue"
              color="white"
              onClick={() => handleEdit(category, index, item)}
            />
          )}
          <IconButton
            aria-label="Remove"
            icon={<FaTrash />}
            size="xs"
            colorScheme="red"
            onClick={() => handleRemoveItem(category, index)}
          />
        </Flex>
      </Flex>
    ));
  


  return (
    <Box>
      <Box padding={10}>
        <Box w="100%" p={4} padding={5} borderWidth="1px" borderRadius="lg" overflow="hidden" width="500px">
          <Heading size="md" mb={4}>
            Upload Requirements
          </Heading>
          <Text fontSize="sm" mb={4}>
            Select a Word/PDF file to upload. It should contain a description of what the program should do.
          </Text>
          <Flex justify="center" mt={4}>
            <Button
              leftIcon={<FaUpload />}
              bg="xelerit.lightBlue"
              color="white"
              variant="solid"
              onClick={onOpen}
            >
              Upload Requirements
            </Button>
          </Flex>
        </Box>
      </Box>
      <Box mt={4}>
        {/* Display Short Task Descriptions */}
        <Box bg="vscode.sidebar" p={4}>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            onClick={() => setShowShortTasks(!showShortTasks)}
          >
            <Heading size="sm" color="white">
              Short Task Description
            </Heading>
            <IconButton
              aria-label="Toggle Short Tasks"
              bg="vscode.sidebar"
              icon={showShortTasks ? <FaAngleDown size="xs" color="white" /> : <FaAngleRight size="xs" color="white" />}
              size="xs"
            />
          </Flex>
          <Collapse in={showShortTasks}>
            <VStack align="start" mt={2} pl={4}>
              {renderItems(response.shortTaskDescription, 'shortTaskDescription')}
              <Flex justify="flex-end" mt={2}>
                <Button
                  size="xs"
                  bg="xelerit.lightBlue"
                  color="white"
                  onClick={() => handleAddItem('shortTaskDescription', 'New Task')}
                >
                  Add Task
                </Button>
              </Flex>

            </VStack>
          </Collapse>
        </Box>

        <Box bg="vscode.sidebar" p={4} mt={4}>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            onClick={() => setShowSequences(!showSequences)}
          >
            <Heading size="sm" color="white">
              Sequences
            </Heading>
            <IconButton
              aria-label="Toggle Sequences"
              bg="vscode.sidebar"
              icon={showSequences ? <FaAngleDown size="xs" color="white" /> : <FaAngleRight size="xs" color="white" />}
              size="xs"
            />
          </Flex>
          <Collapse in={showSequences}>
            <VStack align="start" mt={2} pl={4}>
              {renderItems(response.sequences, 'sequences')}
              <Flex justify="flex-end" mt={2}>
                <Button
                  size="xs"
                  bg="xelerit.lightBlue"
                  color="white"
                  onClick={() => handleAddItem('sequences', 'New Sequence')}
                >
                  Add sequence
                </Button>
              </Flex>
            </VStack>
          </Collapse>
        </Box>

        <Box bg="vscode.sidebar" p={4} mt={4}>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            onClick={() => setShowMainFunctions(!showMainFunctions)}
          >
            <Heading size="sm" color="white">
              Main Functions
            </Heading>
            <IconButton
              aria-label="Toggle Main Functions"
              bg="vscode.sidebar"
              icon={showMainFunctions ? <FaAngleDown size="xs" color="white" /> : <FaAngleRight size="xs" color="white" />}
              size="xs"
            />
          </Flex>
          <Collapse in={showMainFunctions}>
            <VStack align="start" mt={2} pl={4}>
              <Text fontWeight="bold" color="white">
                Main Program
              </Text >
              {renderItems(response.mainFunctions.mainProgram, 'mainProgram')}
              <Button
                  size="xs"
                  bg="xelerit.lightBlue"
                  color="white"
                  onClick={() => handleAddItem('mainProgram', 'New Main Program')}
                >
                  Add main porgram
                </Button>
              <Text fontWeight="bold" mt={4} color="white">
                Sub Functions
              </Text>
              {renderItems(response.mainFunctions.subFunctions, 'subFunctions')}
              <Button
                  size="xs"
                  bg="xelerit.lightBlue"
                  color="white"
                  onClick={() => handleAddItem('subFunctions', 'New Sub Function')}
                >
                  Add sub function
                </Button>
            </VStack>
          </Collapse>
        </Box>
      </Box>
    
     <Modal isOpen={isOpen} onClose={onClose}>
     <ModalOverlay />
     <ModalContent bg="vscode.sidebar">
       <ModalHeader>Upload Requirements</ModalHeader>
       <ModalCloseButton />
       <ModalBody>
         <Input type="file" onChange={handleFileChange} />
       </ModalBody>
       <ModalFooter>
       <Button
          bg="xelerit.lightBlue"
          color="white"
          mr={3}
          onClick={handleFileUpload}
          isLoading={isUploading} // Show loading spinner
          isDisabled={isUploading} // Disable button during upload
        >
          Upload
        </Button>

         <Button bg="white" color="vscode.sidebar" onClick={onClose}>Cancel</Button>
       </ModalFooter>
     </ModalContent>
   </Modal>
   </Box>

  );
};

export default TextRequirementsPanel;
