import React, { useState, useEffect } from "react";
import {
    FormControl,
    FormLabel,
    Select,
    Box,
    Button,
    VStack,
    Heading
} from "@chakra-ui/react";

const SettingsPanel = ({ currentSettings, onSettingsChange }) => {
    const [settings, setSettings] = useState({
        plc_brand: "",
        robot_brand: ""
    });

    useEffect(() => {
        setSettings(currentSettings);
    }, [currentSettings]);

    const handleChange = (field, value) => {
        const updatedSettings = { ...settings, [field]: value };
        setSettings(updatedSettings);
    };

    const handleSave = () => {
        onSettingsChange(settings);
    };

    return (
        <Box className="settings-panel" p={4} borderWidth={1} borderRadius="md" boxShadow="md">
            <Heading size="md" mb={4}>Settings</Heading>
            <VStack spacing={4}>
                <FormControl>
                    <FormLabel>PLC Manufacturer</FormLabel>
                    <Select
                        placeholder="Select PLC Manufacturer"
                        value={settings.plc_brand}
                        onChange={(e) => handleChange("plc_brand", e.target.value)}
                    >
                        <option value="siemens">Siemens</option>
                    </Select>
                </FormControl>
                <FormControl>
                    <FormLabel>Robot Brand</FormLabel>
                    <Select
                        placeholder="Select Robot Brand"
                        value={settings.robot_brand}
                        onChange={(e) => handleChange("robot_brand", e.target.value)}
                    >
                        <option value="estun">Estun</option>
                        <option value="abb">ABB</option>
                        <option value="kuka">KUKA</option>
                    </Select>
                </FormControl>
                <Button colorScheme="blue" onClick={handleSave}>Save</Button>
            </VStack>
        </Box>
    );
};

export default SettingsPanel;