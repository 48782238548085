import React from "react";
import { Box, Flex, Spinner, Text, List, ListItem, Heading, OrderedList } from "@chakra-ui/react";
import { FaRobot } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
const Message = ({ msg, isLoading, isLast, onFeedback }) => {
  const isUser = msg.sender === "user";

  return (
    <Flex justify={isUser ? "flex-end" : "flex-start"} py={2}>
      {!isUser && (
        <Box mr={3}>
          {isLoading && isLast ? (
            <Spinner size="md" color="xelerit.lightBlue" />
          ) : (
            <FaRobot size="24px" />
          )}
        </Box>
      )}
      <Box
        maxW={isUser ? "80%" : "90%"}
        bg={isUser ? "xelerit.lightBlue" : ""}
        color={isUser ? "white" : "gray.100"}
        px={5}
        py={4}
        borderRadius="xl"
      >
        {/* Optional header for "You" or "Bot" */}
        <Text
          fontSize="xs"
          fontWeight="bold"
          mb={1}
          fontFamily='"Segoe UI", "Roboto", "Helvetica Neue", sans-serif'
        >
          {isUser ? "You" : "Bot"}
        </Text>

        {/* Main message content */}
        <ReactMarkdown
          components={{
            p: ({ children }) => (
              <Text
                fontSize="md"
                lineHeight="1.5"
                fontFamily='"Segoe UI", "Roboto", "Helvetica Neue", sans-serif'
                mb={0}
              >
                {children}
              </Text>
            ),
            strong: ({ children }) => (
              <Text
                as="strong"
                fontWeight="bold"
                fontFamily='"Segoe UI", "Roboto", "Helvetica Neue", sans-serif'
              >
                {children}
              </Text>
            ),
            em: ({ children }) => (
              <Text
                as="em"
                fontStyle="italic"
                fontFamily='"Segoe UI", "Roboto", "Helvetica Neue", sans-serif'
                my={4}
              >
                {children}
              </Text>
            ),
            code: ({ children }) => (
              <Box
                as="code"
                borderRadius="xl"
                display="inline-block"
                px={4}
                py={1}
                fontSize="xs"
                bg="vscode.sidebar"
                color="white"
                fontFamily="Viscose, monospace"
                maxWidth={"80%"}
            
                
              >
                {children}
              </Box>
            ),
            ul: ({ children }) => (
              <List
                styleType="disc"
                pl={5}
                fontSize="md"
                lineHeight="1.5"
                fontFamily='"Segoe UI", "Roboto", "Helvetica Neue", sans-serif'
                my={4}
              >
                {children}
              </List>
            ),
            ol: ({ children }) => (
              <OrderedList
                styleType="decimal"
                pl={5}
                fontSize="md"
                lineHeight="1.2"
                fontFamily='"Segoe UI", "Roboto", "Helvetica Neue", sans-serif'
                my={4}
              >
                {children}
              </OrderedList>
            ),
            li: ({ children }) => (
              <ListItem mb={1}>
                <Text
                  fontSize="md"
                  lineHeight="1.5"
                  fontFamily='"Segoe UI", "Roboto", "Helvetica Neue", sans-serif'
                  my={2}
                >
                  {children}
                </Text>
              </ListItem>
            ),
            h3: ({ children }) => (
                <Heading as="h3" size="md" my={4} fontFamily='"Segoe UI", "Roboto", "Helvetica Neue", sans-serif'>
                  {children}
                </Heading>
              ),

              h2: ({ children }) => (
                <Heading as="h3" size="sm" my={3} fontFamily='"Segoe UI", "Roboto", "Helvetica Neue", sans-serif'>
                  {children}
                </Heading>
              ),
          }}
        >
          {msg.content}
        </ReactMarkdown>

        
      </Box>
    </Flex>
  );
};

export default Message;


