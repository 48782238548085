import React from 'react';
import { Box, Container, Heading, Text, Link } from '@chakra-ui/react';

const Terms = () => {
  return (
    <Box as="main" py={10}>
      <Container maxW="container.xl">
        <Heading mb={6}>Terms of Use</Heading>

        {/* Section 1: Technical Disclaimer */}
        <Text mb={4} fontWeight="bold">1. Technical Disclaimer</Text>
        <Text mb={4}>
          Xelerit-Robotics (hereinafter referred to as Xelerit) provides a one-stop platform, transforming production line specifications into robot-ready, executable, and tested code to enhance automation engineers in automating industrial production lines. Features include:
        </Text>
        <Text mb={4}>
          - Text to requirements conversion: Transforming text into engineering requirements.<br />
          - Chat-based documentation retrieval: Enabling engineers to quickly access relevant information.<br />
          - PLC-to-Robot variable mapping: Facilitating the seamless transition of variables between PLC systems and robotic applications.<br />
          - Requirements to flow diagram creation: Simplifying complex processes by visualizing requirements in clear and structured diagrams.<br />
          - Flow diagram to robot-ready executable code: Converting flow diagrams into executable robot-ready code.<br />
          - Code correction and optimization through simulation: Refining generated code based on templates and simulation feedback.<br />
        </Text>
        <Text mb={4}>
          Xelerit assumes no responsibility for the correctness, adequacy, or compatibility of outputs generated by the platform. All outputs are provided "as is" and "as available." It is the users sole responsibility to verify, test, and validate the outputs.
        </Text>

        {/* Section 2: Exclusion of Liability */}
        <Text mb={4} fontWeight="bold">2. Exclusion of Liability for Damages</Text>
        <Text mb={4}>
          Xelerit disclaims all liability for damages resulting from the use, implementation, or interpretation of outputs produced by the platform. This includes:
        </Text>
        <Text mb={4}>
          - Malfunctions or damages caused by incorrect or inadequate outputs.<br />
          - Financial losses, including lost revenue, profits, or data.<br />
          - Any direct or indirect damages resulting from reliance on outputs.
        </Text>
        <Text mb={4}>
          The user accepts full responsibility for ensuring the safe and appropriate use of the platform features.
        </Text>

        {/* Section 3: Data Privacy & Protection */}
        <Text mb={4} fontWeight="bold">3. Data Privacy & Protection Disclaimer</Text>
        <Text mb={4}>
        Xelerit is committed to safeguarding the privacy of its users and ensuring compliance with applicable data protection laws, including the Swiss Federal Data Protection Act (LPD) and the European General Data Protection Regulation (GDPR). By using Xelerit's platform, users agree to the collection and processing of personal data as outlined in our Privacy Policy.  <Link href="/privacy" color="blue.500">Privacy Policy</Link>.
        The platform processes personal data solely for the purpose of providing and improving its services and products. Xelerit retains and processes input data provided by users as necessary to fulfill the requested services. After service fulfillment, Xelerit anonymizes this data -ensuring it is fully dissociated from any user or company - for internal purposes such as algorithm improvement and AI training. Anonymized data is never shared with third parties. 
        </Text>
        <Text mb={4}>
          While Xelerit takes all reasonable precautions to secure user data, no system can fully eliminate the risk of data breaches.
        </Text>

        {/* Section 4: Cookie and Third-Party Tracking */}
        <Text mb={4} fontWeight="bold">4. Cookie and Third-Party Tracking Disclaimer</Text>
        <Text mb={4}>
        Xelerit employs cookies and similar technologies to enhance user experience, personalize content, and improve platform performance. Cookies may collect data such as browser type, IP address, and session duration. Users can manage cookie preferences via the  <Link href="/cookie-policy" color="blue.500">consent banner</Link> displayed upon accessing the platform, in compliance with GDPR regulations. Additionally, Xelerit's website may include links to third-party websites. While these links are provided for user convenience, Xelerit is not responsible for the privacy practices, content, or security measures of such external sites. Users access third-party sites at their own risk, and Xelerit disclaims all liability for losses or damages arising from the use of linked websites.
        </Text>

        {/* Section 5: Acceptance of Terms */}
        <Text mb={4} fontWeight="bold">5. Acceptance of Terms</Text>
        <Text mb={4}>
        The use of the Xelerit platform constitutes full acceptance of these Terms & Conditions, including all the liability limitations described above. The user declares that they have read and understood the Terms & Conditions of Use and accept full responsibility for the use of the outputs generated through the platform. 
        </Text>
      </Container>
    </Box>
  );
};

export default Terms;

