import { MarkerType } from "reactflow";

export const idtNodes = [
  {
    id: "1",
    type: "taskNode",
    data: {
      fileName: "Main.erp",
      input:
        "Guard signals (Guard1–4), Estop, ToolNr_Array (for cycle tool selection)",
      description: `Coordinates the overall process flow. 
- Initializes and loads configurations.
- Checks safety conditions and handles errors.
- Determines whether to perform welding or a tool change based on the current cycle.
- Proceeds through cycles until completion or error.`,
      output:
        "State transitions controlling ToolChange_SM and Welding_SM; cycle progression signals",
    },
    position: { x: 100, y: -100 },
    hidden: false,
  },
  {
    id: "2",
    type: "taskNode",
    data: {
      fileName: "ToolChange_SM.erp",
      input:
        "current_tool_number, next_tool_number, PLC signals for attach/detach confirmation",
      description: `Manages automated tool changes:
- Determines when to detach and attach tools.
- Moves the robot to correct tool positions.
- Executes attach/detach operations and confirms completion to PLC.`,
      output:
        "Signals indicating completed tool changes and updated current_tool_number",
    },
    position: { x: 700, y: -300 },
    hidden: false,
  },
  {
    id: "3",
    type: "taskNode",
    data: {
      fileName: "Welding_SM.erp",
      input:
        "Welding positions array, appropriate welding tool",
      description: `Orchestrates the welding process:
- Moves to weld points.
- Performs the weld and signals completion.
- Cycles through welding positions until done.
      `,
      output:
        "Signals indicating welding start/finish states",
    },
    position: { x: 1200, y: -300 },
    hidden: false,
  },
  {
    id: "4",
    type: "taskNode",
    data: {
      fileName: "Error_sm.erp",
      input:
        "PlcCommSignal, SafetyGuardSignals, EStopSignals",
      description: `Continuously checks safety and PLC communication:
- If a guard is open or Estop is triggered, transitions system into an error state.
- Waits for conditions to normalize before allowing process continuation.`,
      output:
        "Status signals for safety and communication, AlarmSignal in case of unsafe conditions",
    },
    position: { x: 1000, y: 200 },
    hidden: false,
  },
];


export const idtEdges = [
  {
    id: "e1-2",
    source: "1",
    target: "2",
    animated: true,
    type: "smoothstep",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: "#000",
    },
  },
  {
    id: "e2-3",
    source: "2",
    target: "3",
    animated: true,
    type: "smoothstep",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: "#000",
    },
  },
  {
    id: "e3-4",
    source: "1",
    target: "4",
    animated: true,
    type: "smoothstep",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: "#000",
    },
  },
  
 
];
