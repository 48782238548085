import React, { memo } from "react";
import { Handle } from "reactflow";
import { Box, Text, VStack, HStack, Heading } from "@chakra-ui/react";
import { FaExclamationCircle } from "react-icons/fa";

const ErrorHandlingNode = ({ data, isConnectable }) => {
  
  const actions = data.actions || [];

  return (
    <div
      style={{
        background: "#1e1e1e",
        color: "white",
        padding: "10px",
        borderRadius: "5px",
        border: "2px solid red",
        width: "220px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
        position: "relative",
      
      }}
    >
      {/* Input Handle */}
      <Handle
        type="target"
        position="left"
        id="input"
        isConnectable={isConnectable}
      />

      {/* Icon for error indication */}
      <Box position="absolute" top="5px" right="5px">
        <FaExclamationCircle color="red" fontSize="1.5em" />
      </Box>

      <Heading as="h3" size="sm" mb={2}>
        Failure
        </Heading>

        {/* Recovery Actions */}
        <Box p={2} borderRadius="md" width="100%">
          <Text fontSize="xs" fontWeight="bold" color="gray.300">
            Alarms
          </Text>
          {data.alarms.map((alarm, index) => (
            <HStack key={`action-label-${index}`} spacing={1}>
          <Text fontSize="xs" color="gray.400">
            {alarm.name}: {alarm.message} (Criticality: {alarm.criticality})
          </Text>
            </HStack>
          ))}
        </Box>

        {/* Output Handle */}
      <Handle
        type="source"
        position="right"
        id="output"
        isConnectable={isConnectable}
      />
    </div>
  );
};

export default memo(ErrorHandlingNode);
