import React, { memo } from "react";
import { Handle } from "reactflow";

const OutputNode = ({ data, isConnectable }) => {
    return (
        <div
        style={{
            background: "#1e1e1e",
            color: "white",
            padding: "10px",
            borderRadius: "5px",
            width: "300px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            cursor: "pointer",
            position: "relative",
          }}
        >
          <strong>Outputs</strong>
          <ul>    
        {data.vars && data.vars.map((input, index) => (
            <li key={index}>
                <strong>{input.name}</strong>: {input.defaultValue}
            </li>
        ))}
    </ul>
          <Handle
            type="target"
            position="top"
            id="input-output"
            isConnectable={isConnectable}
            style={{ width: '12px', height: '12px' }}
          />
        </div>
      );
    };

export default memo(OutputNode);

