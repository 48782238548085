import React, { useState, useEffect, useRef } from "react";
import ChatPanel from "../../components/panels/chatPanel";
import SidebarBase from "../../components/Layout/SidebarBase";
import FlowDiagramPanel from "../../components/panels/flowDiagramPanel";
import { Flex, Box, HStack, VStack, Select, Heading, Text, Button, useDisclosure, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input
} from "@chakra-ui/react";
import { useTheme } from "@chakra-ui/react";
import ReactFlow, { useNodesState, useEdgesState, MarkerType } from "reactflow";
import { generateFlowDiagram } from "../../api/flowdiagram";
import { initialNodes, intialEdges } from "../../components/Diagram/initialData";
import GroupNodeDetails from "../../components/panels/groupNodePanel";
import CodeEditor from "../../components/CodeEditor";
import TranslatePanel from "../../components/panels/translatePanel";
import Footer from "../../components/Layout/Footer";
import FeedbackComponent from "../../components/Feedback";
import { MultiSelect, useMultiSelect } from 'chakra-multiselect'
import { sendRobotRequest } from "../../api/feedback";

const BaseHome = ({user}) => {
  const theme = useTheme();
  const [activePanel, setActivePanel] = useState("chat");
  const [panelWidths, setPanelWidths] = useState({
    chat: 100,
    profile: 50,
    flowdiagram: 100,
    translator: 100,
  });
  const { value, options, onChange } = useMultiSelect({
    value: [],
    options: ["kuka", "abb", "estun"],
  })
  const [showCodeEditor, setShowCodeEditor] = useState(false);
  const [codeFiles, setCodeFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [flowLoading, setFlowLoading] = useState(false);
  const [currentPanelWidth, setCurrentPanelWidth] = useState(panelWidths[activePanel]);
  const [selectedRobotBrand, setSelectedRobotBrand] = useState("estun");
  const [questionCount, setQuestionCount] = useState(0); // Add state for question count
  const [translateCount, setTranslateCount] = useState(0); // Add state for translation count
  const [nodeClickCount, setClickNodeCount] = useState(0); // Add state for translation count

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(intialEdges);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedGroupNode, setSelectedGroupNode] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isRequestOpen, onOpen: onRequestOpen, onClose: onRequestClose } = useDisclosure();
  const [parsedUser, setParsedUser] = useState(JSON.parse(user));
  const  [robotRequest, setRobotRequest] = useState("");

  useEffect(() => {
    console.log("Selected Robot Brand:", selectedRobotBrand);
    
  }, [selectedRobotBrand]);

  useEffect(() => {
    if (activePanel) {
      setCurrentPanelWidth(panelWidths[activePanel] || 50);
    }
  }, [activePanel, panelWidths]);

  const handleRobotBrandChange = (event) => {
    setSelectedRobotBrand(event.target.value);
  };

  const generateFlowDiagram = async () => {
    setFlowLoading(true);
    console.log("Generated Flow Diagram:", nodes, edges);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setFlowLoading(false);
  };

  const onGroupNodeSelect = (node, childNodes) => {
    console.log("Group node selected:", node, childNodes);
    updatePanelWidth("flowdiagram", 50);

    // Set the selected group node and its child nodes
    setSelectedGroupNode({ node, childNodes });

    setClickNodeCount((prevCount) => {
      const newCount = prevCount + 1;
      if (newCount >= 5) {
        onOpen(); // Open the feedback modal after 3 questions
        return 0; // Reset the count
      }
      return newCount;
    });
  };

  const updatePanelWidth = (panel, width) => {
    setPanelWidths((prevWidths) => ({
      ...prevWidths,
      [panel]: width,
    }));
    setCurrentPanelWidth(width);
  };

  const handlePanelSelect = (panel) => {
    setActivePanel(panel);
    if (panel === "translator" || panel === "chat") {
      setSelectedGroupNode(null); // Show CodeEditor for files panel
    }
  };

  const handleQuestionAsked = async () => {
    setQuestionCount((prevCount) => {
      const newCount = prevCount + 1;
      if (newCount >= 3) {
        return 0; // Reset the count
      }
      return newCount;
    });

    if (questionCount + 1 >= 3) {
      // wait 3 sec
      await new Promise((resolve) => setTimeout(resolve, 1000));
      onOpen(); // Open the feedback modal after 3 questions
    }
  };

  const handleTranslateClicked = () => {
    setTranslateCount((prevCount) => {
      const newCount = prevCount + 1;
      if (newCount >= 2) {
        onOpen(); // Open the feedback modal after 3 questions
        return 0; // Reset the count
      }
      return newCount;
    });
  };

  // const handleNodeClicked = () => {
  //   setClickNodeCount((prevCount) => {
  //     const newCount = prevCount + 1;
  //     if (newCount >= 2) {
  //       onOpen(); // Open the feedback modal after 3 questions
  //       return 0; // Reset the count
  //     }
  //     return newCount;
  //   });
  // };

  const handleSendRequest = async () => {
    const payload = {
      user_id: parsedUser.id,
      robot_brand: robotRequest,
    };
  
    console.log("Request Payload:", payload);
  
    try {
      const response = await sendRobotRequest(payload);
      console.log("Response:", response);
    } catch (error) {
      console.error("Error sending request:", error);
    }
    finally
    {
      onRequestClose();
    }
  };
  

  return (
    <Flex direction="column" height="100vh">
      <FeedbackComponent isOpen={isOpen} onClose={onClose} userId={parsedUser.id} />
      {/* Main Content */}
      <Flex flex="1" direction="row" overflow="hidden">
        <SidebarBase onPanelSelect={handlePanelSelect} />
        {activePanel && (
          <Box
            width={`${currentPanelWidth}%`}
            bg={theme.colors.vscode.background}
            color="white"
            overflowY="auto"
            boxShadow="lg"
            height="97vh"
          >
            {activePanel === "chat" && (
              <Box padding={4}>
                <HStack justifyContent={"flex-end"} >
                <VStack justifyContent={"center"}>
                  <Select
                    width={"200px"}
                    placeholder="Select Robot Brand"
                    value={selectedRobotBrand}
                    onChange={handleRobotBrandChange}
                    size="sm"
                    bg="vscode.background"
                    color="white"
                    defaultValue={"estun"}
                  >
                    <option value="estun">Estun</option>
                    <option value="kuka">KUKA</option>
                    <option value="abb">ABB</option>
                    
                  </Select>
                  <Button
                    size="sm"
                    width={"200px"}
                    bg="vscode.sidebar"
                    color="white"
                    onClick={onRequestOpen}
                  >
                    Request Another Robot
                  </Button>
                </VStack>
                </HStack>
                <ChatPanel robotBrand={selectedRobotBrand} onQuestionAsked={handleQuestionAsked} userId={parsedUser.id}/>
              </Box>
            )}
            {activePanel === "flowdiagram" && (
              <FlowDiagramPanel
                nodes={nodes}
                edges={edges}
                setNodes={setNodes}
                setEdges={setEdges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                generateFlowDiagram={generateFlowDiagram}
                loading={flowLoading}
                onNodeSelect={setSelectedNode}
                onGroupNodeSelect={onGroupNodeSelect}
                
              />
            )}

            {activePanel === "translator" && <TranslatePanel onTranslateClick={handleTranslateClicked} userId={parsedUser.id}/>}
            
          </Box>
        )}


        <Box flex="1" bg="vscode.background" height="97vh">
          {selectedGroupNode ? (
            <GroupNodeDetails
              groupNode={selectedGroupNode.node}
              childNodes={selectedGroupNode.childNodes}
              onUpdate={(updatedGroupNode, updatedChildNodes) => {
                setNodes((prevNodes) =>
                  prevNodes.map((node) =>
                    node.id === updatedGroupNode.id
                      ? updatedGroupNode
                      : updatedChildNodes.find((child) => child.id === node.id) || node
                  )
                );
              }}
              onClose={() => {
                setSelectedGroupNode(null); // Close the panel when done
                setShowCodeEditor(true); // Show the code editor panel
                updatePanelWidth("flowdiagram", 100); // Reset the width of the flow diagram panel
              }}
            />
          ) : null}
        </Box>
      </Flex>

      {/* Footer */}
      <Box>
        <Footer />
      </Box>

      <Modal isOpen={isRequestOpen} onClose={onRequestClose} isCentered>
        <ModalOverlay  bg="blackAlpha.600" backdropFilter="blur(5px)" />
        <ModalContent bg="vscode.sidebar">
          <ModalHeader>Request Another Robot</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Provide details about the robot you want to request.</Text>
            <Select placeholder="Select Robot Brand" mt={4} mb={4} onChange={(e) => setRobotRequest(e.target.value)}>
            {["Fanuc", "Yaskawa", "Universal Robots", "Epson", "Staubli", "Comau", "Omron", "Nachi", "Denso", "Kawasaki", "Doosan ", "Hanwha ", "Schunk", "Festo", "Techman", "Reis"]
.sort().map((brand) => (
                      <option key={brand} value={brand}>
                        {brand.charAt(0).toUpperCase() + brand.slice(1)}
                      </option>
                    ))}
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" color="white" mr={3} onClick={onRequestClose}>
              Close
            </Button>
            <Button bgColor="xelerit.lightBlue" color="white" onClick={handleSendRequest}>Submit Request</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </Flex>
  );
};

export default BaseHome;

