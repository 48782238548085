import React, { memo } from "react";
import { Handle } from "reactflow";
import { Box, Text, VStack, HStack, Heading } from "@chakra-ui/react";

const RecoveryNode = ({ data, isConnectable }) => {
    const actions = data.actions || [];
  return (
    <div
      style={{
        background: "#1e1e1e",
        color: "white",
        padding: "10px",
        borderRadius: "5px",
        border: "2px solid green",
        width: "220px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
        position: "relative",
      }}
    >
      <Heading as="h3" size="sm" mb={2}>
        Recovery
        </Heading>

      
      {/* Recovery Actions */}
      <Box p={2} borderRadius="md" width="100%">
        <Text fontSize="xs" fontWeight="bold" color="gray.300">
          Recovery Actions:
        </Text>
        {data.actions.map((action, index) => (
          <HStack key={`action-label-${index}`} spacing={1}>
            <Text fontSize="xs" color="gray.400">
              {action}
            </Text>
          </HStack>
        ))}
      </Box>

      <Handle
        type="target"
        position="left"
        id="recovery-input"
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position="right"
        id="recovery-output"
        isConnectable={isConnectable}
      />
    </div>
  );
};

export default memo(RecoveryNode);

