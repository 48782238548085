// api/translateApi.js
import apiClient from './apiClient';

/**
 * Translates code from one language to another.
 * @param {Object} payload - The translation payload.
 * @param {string} payload.code - The source code to translate.
 * @param {string} payload.from_language - The source language.
 * @param {string} payload.to_language - The target language.
 * @returns {Promise<Object>} - The translated code.
 */
export const translateCode = async (payload) => {
    return await apiClient.post('/translate/translate', payload);

}