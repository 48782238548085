import React, { useState, useEffect } from "react";
import { useAuth } from "../../hooks/AuthProvider";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Layout/SidebarPremium";
import { Flex, Box, Heading, Text, Button, useDisclosure } from "@chakra-ui/react";
import { useTheme } from "@chakra-ui/react";
import CodeEditor from "../../components/CodeEditor";
import TextRequirementsPanel from "../../components/panels/textRequirementsPanel";
import ProfilePanel from "../../components/panels/profilePanel";
import VariablesPanel from "../../components/panels/variablesPanel";
import ChatPanel from "../../components/panels/chatPanel";
import FlowDiagramPanel from "../../components/panels/flowDiagramPanel";
import TaskPanel from "../../components/panels/tasksPanel";
import TaskDetailsPanel from "../../components/panels/TaskDetailsPanel";
import ProjectPopup from "../../components/ProjectPopUp";
import SettingsPanel from "../../components/panels/settingsPanel"; // Import the new component
import { generateCode } from "../../api/code";
import { generateFlowDiagram } from "../../api/flowdiagram";
import { getProjectById } from "../../api/projects";
import { initChat } from "../../api/message_chat_bot"; // Import the initChat API function
import Footer from "../../components/Layout/Footer";
import {initialNodes, intialEdges} from  "../../components/Diagram/initialData";

import ReactFlow, { useNodesState, useEdgesState, MarkerType } from "reactflow";

const PremiumHome = () => {
  const { user, logOut } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [activePanel, setActivePanel] = useState("variables");
  const [panelWidths, setPanelWidths] = useState({
    textRequirements: 40,
    profile: 50,
    variables: 90,
    chat: 50,
    flowDiagram: 50,
    settings: 50,
    tasksPanel: 20,
  });
  const [currentPanelWidth, setCurrentPanelWidth] = useState(panelWidths[activePanel]);

  const [showCodeEditor, setShowCodeEditor] = useState(true);
  const [codeFiles, setCodeFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [tasks, setTasks] = useState([]);
  const [requirementsData, setRequirementsData] = useState({
    shortTaskDescription: [],
    sequences: [],
    mainFunctions: { mainProgram: [], subFunctions: [] },
  });
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(intialEdges);

  const [selectedNode, setSelectedNode] = useState(null);
  const [flowLoading, setFlowLoading] = useState(false);
  const [codeLoading, setCodeLoading] = useState(false);
  const [projectData, setProjectData] = useState({
    plc_brand: "",
    robot_brand: "",
  });

  useEffect(() => {
    if (activePanel) {
      setCurrentPanelWidth(panelWidths[activePanel] || 50);
    }
  }, [activePanel, panelWidths]);

  useEffect(() => {
    onOpen(); // Automatically open the popup on mount
  }, [onOpen]);

  useEffect(() => {
    if (selectedNode) {
      setShowCodeEditor(false); // Switch to Task Details view when a node is selected
    }
  }, [selectedNode]);

  const fetchProjectDetails = async (projectId) => {
    try {
      const response = await getProjectById(projectId);
      setProjectData({
        plc_brand: response.plc_brand || "siemens",
        robot_brand: response.robot_brand || "estun",
      });
      // Initialize chat with the robot brand ID
      await initChat({ robot_brand_id: response.robot_brand || "estun" });
    } catch (error) {
      console.error("Error fetching project details:", error);
    }
  };

  const handleSelectProject = (projectId) => {
    fetchProjectDetails(projectId);
    onClose();
  };

  const handleSettingsChange = (updatedSettings) => {
    setProjectData(updatedSettings);
  };

  const toggleView = () => {
    setShowCodeEditor((prev) => !prev);
  };

  const generateFlowDiagramFromInput = async () => {
    try {
      setFlowLoading(true);
      const payload = {
        shortTaskDescription: requirementsData.shortTaskDescription,
        sequences: requirementsData.sequences,
        mainFunctions: requirementsData.mainFunctions,
      };
      const response = await generateFlowDiagram(payload);
      if (response.initialNodes && response.initialEdges) {
        setNodes(response.initialNodes);
        setEdges(response.initialEdges);
      }
    } catch (error) {
      console.error("Error generating flow diagram:", error);
    } finally {
      setFlowLoading(false);
    }
  };

  const generateCodeFromDiagram = async () => {
    try {
      setCodeLoading(true);
      const payload = { nodes, edges, ...requirementsData };
      const response = await generateCode(payload);
      const responseData = JSON.parse(response.response);

      const generatedTasks = responseData.tasks.map((task) => ({
        fileName: task.file_name,
        variables: task.variables || [],
      }));

      const generatedCodeFiles = responseData.tasks.map((task) => ({
        fileName: task.file_name,
        content: task.content || "// No code content generated",
      }));

      setTasks((prev) => [...prev, ...generatedTasks]);
      setCodeFiles((prev) => [...prev, ...generatedCodeFiles]);
    } catch (error) {
      console.error("Error generating code:", error);
    } finally {
      setCodeLoading(false);
    }
  };

  const handleDrag = (e) => {
    const newWidth = (e.clientX / window.innerWidth) * 100;
    if (newWidth > 20 && newWidth < 100) {
      setCurrentPanelWidth(newWidth);
    }
  };

  const startDrag = () => {
    document.addEventListener("mousemove", handleDrag);
    document.addEventListener("mouseup", () => {
      document.removeEventListener("mousemove", handleDrag);
    });
  };

  const handleLogout = () => {
    logOut();
    navigate("/login");
  };

  return (
    <Flex height="100vh">
    <Flex height="100vh">
      <Sidebar onPanelSelect={setActivePanel} />

      {activePanel && (
        <Box
          width={`${currentPanelWidth}%`}
          bg={theme.colors.vscode.background}
          color="white"
          padding="20px"
          overflowY="auto"
          boxShadow="lg"
        >
          {activePanel === "textRequirements" && (
            <TextRequirementsPanel
              data={requirementsData}
              onUpdate={setRequirementsData}
            />
          )}
          {activePanel === "profile" && (
            <Box>
              <Heading>Welcome, {user?.username || "User"}!</Heading>
              <Text>Email: {user?.email}</Text>
            </Box>
          )}
          {activePanel === "variables" && (
            <VariablesPanel
              plcManufacturer={projectData.plc_brand}
              robotBrand={projectData.robot_brand}
            />
          )}
          {activePanel === "chat" && <ChatPanel robotBrand={projectData.robot_brand} />}
          {/* {activePanel === "flowDiagram" && (
            <FlowDiagramPanel
              nodes={nodes}
              edges={edges}
              setNodes={setNodes}
              setEdges={setEdges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              generateFlowDiagram={generateFlowDiagramFromInput}
              generateCode={generateCodeFromDiagram}
              loading={flowLoading}
              onNodeSelect={setSelectedNode}
            />
          )} */}
          {/* {activePanel === "tasksPanel" && (
            <TaskPanel tasks={tasks} onOpenFile={(fileName) => {
              const fileToOpen = codeFiles.find((file) => file.fileName === fileName);
              if (fileToOpen) {
                setCurrentFileIndex(codeFiles.indexOf(fileToOpen));
              }
            }} />
          )} */}
          {activePanel === "settings" && (
            <SettingsPanel
              currentSettings={projectData}
              onSettingsChange={handleSettingsChange}
            />
          )}
        </Box>
      )}

      {activePanel && (
        <Box
          width="5px"
          bg="gray.500"
          cursor="col-resize"
          onMouseDown={startDrag}
        />
      )}

      {/* <Box flex="1" bg="vscode.background" p={4}>
        <Button backgroundColor="blue.500" color="white" mb={4} onClick={toggleView}>
          {showCodeEditor ? "Show Task Details" : "Show Code Editor"}
        </Button>
        {showCodeEditor ? (
          <CodeEditor
            codeFiles={codeFiles}
            onFilesChange={(updatedFiles) => setCodeFiles(updatedFiles)}
            currentFileIndex={currentFileIndex}
            setCurrentFileIndex={setCurrentFileIndex}
          />
        ) : (
          <TaskDetailsPanel
            nodeData={selectedNode}
            nodes={nodes}
            onUpdate={(updatedNode) => {
              setNodes((prev) =>
                prev.map((node) =>
                  node.id === updatedNode.id
                    ? { ...node, data: { ...node.data, ...updatedNode.data } }
                    : node
                )
              );
            }}
          />
        )}
      </Box> */}

      <ProjectPopup isOpen={isOpen} onClose={onClose} onSelectProject={handleSelectProject} />
    </Flex>
    </Flex>
  );
};

export default PremiumHome;