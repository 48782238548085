import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import AdminDashboard from "./pages/admin/AdminDashboard";
import PremiumHome from "./pages/premium/PremiumHome";
import BaseHome from "./pages/base/BaseHome";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";

import AuthProvider, { useAuth } from "./hooks/AuthProvider";
import ProtectedRoute from "./ProtectedRoute";
import Signup from "./pages/Signup";

const AppRoutes = () => {
  const isAuthenticated = localStorage.getItem("token"); // Example: Check token for authentication
  const user = localStorage.getItem("user"); // Example: Get user from AuthProvider
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/unauthorized" element={<div>Unauthorized</div>} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />

          {/* Protected Routes */}
          <Route
            path="/admin-dashboard"
            element={
              <ProtectedRoute roles={["admin"]}>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/premium-home"
            element={
              <ProtectedRoute roles={["premium"]}>
                <PremiumHome />
              </ProtectedRoute>
            }
          />
          <Route
            path="/base-home"
            element={
              <ProtectedRoute roles={["base"]}>
                <BaseHome user={user} />
              </ProtectedRoute>
            }
          />

          {/* Default Route */}
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default AppRoutes;


