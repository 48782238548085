import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  VStack,
  HStack,
  Input,
  Select,
  Button,
  IconButton,
  Divider,
  Textarea,

} from "@chakra-ui/react";

import { useTheme } from "@chakra-ui/react";

import { FiPlus, FiTrash2, FiEdit, FiSave } from "react-icons/fi";

const VARIABLE_TYPES = ["BOOL", "INT", "REAL", "STRING", "ARRAY", "OBJECT", "FUNCTION"];
const CRITICALITY_TYPES = ["LOW", "MEDIUM", "HIGH"];

const GroupNodeDetails = ({ groupNode, childNodes, onUpdate, onClose }) => {
  // Local state to track input, output, and task nodes
  const [inputNodes, setInputNodes] = useState([]);
  const [outputNodes, setOutputNodes] = useState([]);
  const [taskNode, setTaskNode] = useState(null);
  const [errorHandlingNode, setErrorHandlingNode] = useState(null);
  const [recoveryNode, setRecoveryNode] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [groupLabel, setGroupLabel] = useState(groupNode.data.label || "Group Node");
  const theme = useTheme();


  // Sync local state with childNodes whenever childNodes changes
  useEffect(() => {
    setInputNodes(childNodes.filter((child) => child.type === "inputNode"));
    setOutputNodes(childNodes.filter((child) => child.type === "outputNode"));

    const taskNodes = childNodes.filter((child) => child.type === "taskNode");
    setTaskNode(taskNodes.length > 0 ? taskNodes[0] : null);

    const errorNodes = childNodes.filter((child) => child.type === "errorHandlingNode");
    setErrorHandlingNode(errorNodes.length > 0 ? errorNodes[0] : null);

    const recoveryNodes = childNodes.filter((child) => child.type === "recoveryNode");
    setRecoveryNode(recoveryNodes.length > 0 ? recoveryNodes[0] : null);
  }, [childNodes]);

  const handleNodeUpdate = (nodeId, key, value, nodeType) => {
    const updatedNode =
      nodeType === "errorHandlingNode"
        ? { ...errorHandlingNode, data: { ...errorHandlingNode.data, [key]: value } }
        : { ...recoveryNode, data: { ...recoveryNode.data, [key]: value } };

    if (nodeType === "errorHandlingNode") setErrorHandlingNode(updatedNode);
    if (nodeType === "recoveryNode") setRecoveryNode(updatedNode);

    if (onUpdate) {
      const updatedChildNodes = childNodes.map((child) =>
        child.id === nodeId ? updatedNode : child
      );
      onUpdate(groupNode, updatedChildNodes);
    }
  };

  const handleTaskNodeUpdate = (key, value) => {
    if (!taskNode) return;

    const updatedTaskNode = {
      ...taskNode,
      data: { ...taskNode.data, [key]: value },
    };

    setTaskNode(updatedTaskNode);

    // Notify the parent component of updates
    if (onUpdate) {
      const updatedChildNodes = childNodes.map((child) =>
        child.id === taskNode.id ? updatedTaskNode : child
      );
      onUpdate(groupNode, updatedChildNodes);
    }
  };

  const handleUpdateNode = (nodeId, updatedVars, type) => {
    let updatedNodes;

    if (type === "inputNode") {
      updatedNodes = inputNodes.map((node) =>
        node.id === nodeId
          ? { ...node, data: { ...node.data, vars: updatedVars } }
          : node
      );
      setInputNodes(updatedNodes);
    } else if (type === "outputNode") {
      updatedNodes = outputNodes.map((node) =>
        node.id === nodeId
          ? { ...node, data: { ...node.data, vars: updatedVars } }
          : node
      );
      setOutputNodes(updatedNodes);
    }

    // Notify the parent component of updates
    if (onUpdate) {
      const updatedChildNodes = childNodes.map((child) =>
        child.id === nodeId
          ? { ...child, data: { ...child.data, vars: updatedVars } }
          : child
      );
      onUpdate(groupNode, updatedChildNodes);
    }
  };

  const renderVariable = (variables, updateVars) => {
    return variables.map((variable, index) => (
      <HStack key={index} spacing={2} align="start">
        {isEditMode ? (
          <>
            <Select
              placeholder="Type"
              value={variable.type}
              onChange={(e) => {
                const updatedVariables = [...variables];
                updatedVariables[index].type = e.target.value;
                updateVars(updatedVariables);
              }}
              size="sm"
            >
              {VARIABLE_TYPES.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </Select>
            <Input
              placeholder="Name"
              value={variable.name}
              onChange={(e) => {
                const updatedVariables = [...variables];
                updatedVariables[index].name = e.target.value;
                updateVars(updatedVariables);
              }}
              size="sm"
            />
            <Input
              placeholder="Default Value"
              value={variable.defaultValue}
              onChange={(e) => {
                const updatedVariables = [...variables];
                updatedVariables[index].defaultValue = e.target.value;
                updateVars(updatedVariables);
              }}
              size="sm"
            />
            <IconButton
              icon={<FiTrash2 />}
              size="xs"
              colorScheme="red"
              onClick={() => {
                const updatedVariables = variables.filter((_, i) => i !== index);
                updateVars(updatedVariables);
              }}
            />
          </>
        ) : (
          <>
            <Text>{variable.type}</Text>
            <Text>{variable.name}</Text>
            <Text>{variable.defaultValue}</Text>
          </>
        )}
      </HStack>
    ));
  };

  const renderAlarms = (alarms, updateAlarms) =>
    alarms.map((alarm, index) => (
      <HStack key={index} spacing={2} align="start">
        {isEditMode ? (
          <>
            <Input
              placeholder="Alarm Name"
              value={alarm.name}
              onChange={(e) => {
                const updatedAlarms = [...alarms];
                updatedAlarms[index].name = e.target.value;
                updateAlarms(updatedAlarms);
              }}
              size="sm"
            />
            <Input
              placeholder="Message"
              value={alarm.message}
              onChange={(e) => {
                const updatedAlarms = [...alarms];
                updatedAlarms[index].message = e.target.value;
                updateAlarms(updatedAlarms);
              }}
              size="sm"
            />
            <Select
              placeholder="Criticality"
              value={alarm.criticality}
              onChange={(e) => {
                const updatedAlarms = [...alarms];
                updatedAlarms[index].criticality = e.target.value;
                updateAlarms(updatedAlarms);
              }}
              size="sm"
            >
              {CRITICALITY_TYPES.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </Select>
            <IconButton
              icon={<FiTrash2 />}
              size="xs"
              colorScheme="red"
              onClick={() => {
                const updatedAlarms = alarms.filter((_, i) => i !== index);
                updateAlarms(updatedAlarms);
              }}
            />
          </>
        ) : (
          <>
            <Text>{alarm.name}</Text>
            <Text>{alarm.message}</Text>
            <Text>{alarm.criticality}</Text>
          </>
        )}
      </HStack>
    ));

  const renderRecoveryActions = (actions, updateActions) =>
    actions.map((action, index) => (
      <HStack key={index} spacing={2} align="start">
        {isEditMode ? (
          <>
            <Input
              placeholder="Action"
              value={action}
              onChange={(e) => {
                const updatedActions = [...actions];
                updatedActions[index] = e.target.value;
                updateActions(updatedActions);
              }}
              size="sm"
            />
            <IconButton
              icon={<FiTrash2 />}
              size="xs"
              colorScheme="red"
              onClick={() => {
                const updatedActions = actions.filter((_, i) => i !== index);
                updateActions(updatedActions);
              }}
            />
          </>
        ) : (
          <Text>{action}</Text>
        )}
      </HStack>
    ));

return (
    <Box
        p={4}
        bg="vscode.background"
        color="white"
        right="0"
        top="0"
        height="100%"
        overflowY="auto"
        boxShadow="lg"
    >
        <HStack justify="flex-end" mb={4}>
            
            <IconButton
                icon={isEditMode ? <FiSave/> : <FiEdit />}
                onClick={() => {
                    if (isEditMode) {
                        // Save the updated label to the group node data
                        const updatedGroupNode = { ...groupNode, data: { ...groupNode.data, label: groupLabel } };
                        onUpdate(updatedGroupNode, childNodes);
                    }
                    setIsEditMode(!isEditMode);
                }}
                backgroundColor={isEditMode ? "green.500" : "xelerit.lightBlue"}
                color={"white"}
            />
        </HStack>

        <HStack align="center" mb={4}>
            <Text fontWeight="bold">State name:</Text>
            {isEditMode ? (
                <Input
                    value={groupLabel}
                    onChange={(e) => setGroupLabel(e.target.value)}
                    size="sm"
                />
            ) : (
                <Text>{groupLabel}</Text>
            )}
        </HStack>

        {/* Task Node */}
        {taskNode && (
            <>
                <HStack align="center" mb={4}>
                    <Text fontWeight="bold">Filename:</Text>
                    {isEditMode ? (
                        <Input
                            value={taskNode.data.fileName || ""}
                            onChange={(e) => handleTaskNodeUpdate("fileName", e.target.value)}
                            size="sm"
                        />
                    ) : (
                        <Text>{taskNode.data.fileName}</Text>
                    )}
                </HStack>

                <HStack align="start" mb={4}>
                    <Text fontWeight="bold">Description:</Text>
                    {isEditMode ? (
                        <Textarea
                            value={taskNode.data.description || ""}
                            onChange={(e) => handleTaskNodeUpdate("description", e.target.value)}
                            size="sm"
                            resize="vertical"
                            minHeight="80px"
                        />
                    ) : (
                        <Text>{taskNode.data.description}</Text>
                    )}
                </HStack>
            </>
        )}
        <Divider my={4} />

        {/* Input Nodes */}
        <Text fontWeight="bold" fontSize="md" mb={4}>
            Inputs
        </Text>
        {inputNodes.map((node) => (
            <Box key={node.id} bg="vscode.sidebar" p={4} borderRadius="md" mb={4}>
                <VStack spacing={2} align="stretch">
                    {renderVariable(node.data.vars || [], (updatedVars) =>
                        handleUpdateNode(node.id, updatedVars, "inputNode")
                    )}
                    {isEditMode && (
                        <Button
                            size="xs"
                            onClick={() =>
                                handleUpdateNode(node.id, [
                                    ...(node.data.vars || []),
                                    { type: "", name: "", defaultValue: "" },
                                ], "inputNode")
                            }
                        >
                            <FiPlus /> Add Variable
                        </Button>
                    )}
                </VStack>
            </Box>
        ))}

        <Divider my={4} />

        {/* Output Nodes */}
        <Text fontWeight="bold" fontSize="md" mb={4}>
            Outputs
        </Text>
        {outputNodes.map((node) => (
            <Box key={node.id} bg="vscode.sidebar" p={4} borderRadius="md" mb={4}>
                <VStack spacing={2} align="stretch">
                    {renderVariable(node.data.vars || [], (updatedVars) =>
                        handleUpdateNode(node.id, updatedVars, "outputNode")
                    )}
                    {isEditMode && (
                        <Button
                            size="xs"
                            onClick={() =>
                                handleUpdateNode(node.id, [
                                    ...(node.data.vars || []),
                                    { type: "", name: "", defaultValue: "" },
                                ], "outputNode")
                            }
                        >
                            <FiPlus /> Add Variable
                        </Button>
                    )}
                </VStack>
            </Box>
        ))}
        <Divider my={4} />
        {/* Error Handling Node */}
        {errorHandlingNode && (
            <>
                <Text fontWeight="bold" fontSize="md" mb={4}>
                     Alarms
                </Text>
                <Box bg="vscode.sidebar" p={4} borderRadius="md" mb={4}>
                    <VStack spacing={2} align="stretch">
                        {renderAlarms(
                            errorHandlingNode.data.alarms || [],
                            (updatedAlarms) =>
                                handleNodeUpdate(errorHandlingNode.id, "alarms", updatedAlarms, "errorHandlingNode")
                        )}
                        {isEditMode && (
                            <Button
                                size="xs"
                                onClick={() =>
                                    handleNodeUpdate(
                                        errorHandlingNode.id,
                                        "alarms",
                                        [
                                            ...(errorHandlingNode.data.alarms || []),
                                            { name: "", message: "", criticality: "LOW" },
                                        ],
                                        "errorHandlingNode"
                                    )
                                }
                            >
                                <FiPlus /> Add Alarm
                            </Button>
                        )}
                    </VStack>
                </Box>
            </>
        )}
        <Divider my={4} />
        {/* Recovery Node */}
        {recoveryNode && (
            <>
                <Text fontWeight="bold" fontSize="md" mb={4}>
                    Recovery Actions
                </Text>
                <Box bg="vscode.sidebar" p={4} borderRadius="md" mb={4}>
                    <VStack spacing={2} align="stretch">
                        {renderRecoveryActions(
                            recoveryNode.data.actions || [],
                            (updatedActions) =>
                                handleNodeUpdate(recoveryNode.id, "actions", updatedActions, "recoveryNode")
                        )}
                        {isEditMode && (
                            <Button
                                size="xs"
                                onClick={() =>
                                    handleNodeUpdate(
                                        recoveryNode.id,
                                        "actions",
                                        [...(recoveryNode.data.actions || []), ""],
                                        "recoveryNode"
                                    )
                                }
                            >
                                <FiPlus /> Add Action
                            </Button>
                        )}
                    </VStack>
                </Box>
            </>
        )}

        <Divider my={4} />
        {/* Close Button */}
        <Button backgroundColor={isEditMode ? "xelerit.orange" : "vscode.foreground"} color={isEditMode ? "white" : "black"} size="sm" onClick={onClose} w="full">
            Close
        </Button>
    </Box>
);
};

export default GroupNodeDetails;

