import React from "react";
import { Flex, Input, Button, Box } from "@chakra-ui/react";

const MessageInput = ({ userMessage, setUserMessage, handleSendMessage, isLoading }) => {
  return (
    <Box p={3} borderRadius="xl" bg="vscode.sidebar" shadow="lg">
    <Flex align="center">
      <Input
        placeholder="Type your message..."
        value={userMessage}
        onChange={(e) => setUserMessage(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !isLoading) handleSendMessage();
        }}
        isDisabled={isLoading}
      />
      <Button ml={2} bg="xelerit.lightBlue" color="white" onClick={() => handleSendMessage()} isLoading={isLoading}>
        Send
      </Button>
    </Flex>
    </Box>

  );
};

export default MessageInput;