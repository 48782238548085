import apiClient from "./apiClient";

/**
 * Perform chatbot Call.
 * @param {Object} data - The user message.
 * @returns {Object} - Returns the bot response.
 */

export const message_and_response = async (data) => {
    const response = await apiClient.post("/chat", data); // Call Flask API
    return response.context[response.context.length - 1];
  };


  
export const message_and_response_stream = async (data, onChunk) => {
    await apiClient.postStream('/chat/stream', data, onChunk);
  };

export const initChat = async (data) => {
      const response = await apiClient.post('/chat/init', data);
      return response;
  };

export const isChatInitialized = async (robotBrand) => {
  const response = await apiClient.get(`/chat/loaded?brand=${robotBrand}`);
  console.log(response);
  return response;
}