import React, { useState, useRef, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { message_and_response_stream } from "../../api/message_chat_bot";
import { motion } from "framer-motion";
import { FaUserCircle, FaRobot } from "react-icons/fa";
import { FiTrash2 } from "react-icons/fi";
import {
  Box,
  Button,
  Input,
  Flex,
  VStack,
  Wrap,
  WrapItem,
  Heading,
  IconButton,
  Spinner,
} from "@chakra-ui/react";
import MessageInput from "../Chat/MessageInput";
import InitialMessagePrompt from "../Chat/InitialMessagePrompt"; // Import the new component
import MessageList from "../Chat/MessageList"; // Import the new component

const ChatPanel = ({ robotBrand }) => {
  const [conversationId] = useState(uuidv4());
  const [isFirstMessageSent, setIsFirstMessageSent] = useState(false);
  const predefinedQuestions = [
    "What does MovJ do?",
    "What is APOS?",
    "What are the parameters of MovCircle?",
    "How can I integrate you into my project?",
  ];
  const text = "Start by typing your question or choose one below!";
  const letters = text.split("");

  const [messages, setMessages] = useState([]);
  const [userMessage, setUserMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSendMessage = async (message) => {
    const msg = message || userMessage;
    if (!msg.trim()) return;

    // Add user message to the chat
    const newUserMessage = { sender: "user", content: msg };
    setMessages((prev) => [...prev, newUserMessage]);

    // Mark the first message as sent
    if (!isFirstMessageSent) {
      setIsFirstMessageSent(true);
    }

    const payload = {
      conversation_id: conversationId,
      question: msg,
      top_k: 5,
      robot_brand_id: robotBrand, // Include robotBrand in the payload
    };

    // Add a placeholder for the bot's response
    const botMessagePlaceholder = { sender: "bot", content: "", complete: false };
    setMessages((prev) => [...prev, botMessagePlaceholder]);

    try {
      setIsLoading(true);

      // Use the streaming API to update bot's response progressively
      await message_and_response_stream(payload, (chunk) => {
        setMessages((prev) => {
          const updatedMessages = [...prev];
          const botMessageIndex = updatedMessages.findIndex(
            (msg) => msg.sender === "bot" && !msg.complete // Find the incomplete bot's message
          );

          if (botMessageIndex !== -1) {
            updatedMessages[botMessageIndex].content += chunk; // Append the chunk to the bot's message
          }

          return updatedMessages;
        });
      });

      // Finalize the bot's response after streaming completes
      setMessages((prev) =>
        prev.map((msg) =>
          msg.sender === "bot" && !msg.complete
            ? { ...msg, complete: true } // Mark as complete
            : msg
        )
      );
    } catch (error) {
      console.error("Error during streaming:", error);

      // Replace the bot's placeholder with an error message
      setMessages((prev) => [
        ...prev.slice(0, -1),
        { sender: "bot", content: "Sorry, something went wrong. Please try again later.", complete: true },
      ]);
    } finally {
      setIsLoading(false);
      setUserMessage("");
    }
  };

  const handleInputChange = (e) => setUserMessage(e.target.value);

  const handleClearConversation = () => {
    setMessages([]);
    setIsFirstMessageSent(false);
  };

  if (!isFirstMessageSent) { // Render the InitialMessagePrompt component if the first message is not sent
    return (
    <Flex justify="center" align="center" height="100%">
      <InitialMessagePrompt
        letters={letters}
        userMessage={userMessage}
        setUserMessage={setUserMessage}
        handleSendMessage={handleSendMessage}
        isLoading={isLoading}
        predefinedQuestions={predefinedQuestions}
      />
    </Flex>
    )
  }

  return (
    <Flex height="100vh" justify="center">
      <Box width={{ base: "100%", md: "700px", lg: "1200px" }} position="relative">
        
        <Flex height="90vh" overflowY="auto" borderRadius="md" shadow="inner" ref={chatContainerRef} direction="column">
          <MessageList messages={messages} isLoading={isLoading} />
        </Flex>

        {isFirstMessageSent && (
          <Box position="absolute" bottom="16px" left="0" right="0" padding="0 8px">
            <MessageInput
              userMessage={userMessage}
              setUserMessage={setUserMessage}
              handleSendMessage={handleSendMessage}
              isLoading={isLoading}
            />
          </Box>
        )}

        
      </Box>
    </Flex>
  );
};

export default ChatPanel;