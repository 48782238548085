import React, { useCallback, useState, useMemo, useEffect } from "react";
import ReactFlow, {
  addEdge,
  Controls,
  Background,
  MarkerType,
} from "reactflow";
import "reactflow/dist/style.css";
import { Box,
  Button,
  VStack,
  HStack,
  IconButton,
  Tooltip,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  background,
 } from "@chakra-ui/react";
import { FaPlus, FaList, FaTrashAlt, FaUndo, FaSave, FaPlay, FaDownload } from "react-icons/fa";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import TaskNode from "./TaskNode";
import ErrorHandlingNode from "./ErrorHandlingNode";
import InputNode from "./InputNode";
import OutputNode from "./OutputNode";
import RecoveryNode from "./RecoveryNode";
import GroupNode from "./GroupNode";

import "./styles.css";



const connectionLineStyle = { stroke: "#fff" };
const snapGrid = [20, 20];

const CustomNodeFlow = ({
  hidden,
  nodes,
  edges,
  setNodes,
  setEdges,
  onNodesChange,
  onEdgesChange,
  onNodeSelect, // Pass down this prop to handle node selection
  onGroupNodeSelect,
}) => {
  const [selectedElement, setSelectedElement] = useState(null);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    console.log("Nodes:", JSON.stringify(nodes, null, 2));
    console.log("Edges:", JSON.stringify(edges, null, 2));
  }, [nodes, edges]);

  const nodeTypes = useMemo(
    () => ({
      inputNode: InputNode,
      outputNode: OutputNode,
      recoveryNode: RecoveryNode,
      taskNode: TaskNode,
      errorHandlingNode: ErrorHandlingNode,
      group: GroupNode,
    }),
    []
  );

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));


  const clearFlow = () => {
    setHistory([...history, { nodes, edges }]);
    setNodes([]);
    setEdges([]);
  };

  const createSubsystem = (idOffset) => {
    const parentNodeId = `${idOffset}-parent`;
  
    const subsystemNodes = [
      {
        id: parentNodeId,
        type: "group",
        data: { label: "State x" },
        position: { x: 0, y: 0 },
       

      },
      {
        id: `${idOffset + 1}`,
        type: "inputNode",
        data: {
          vars: [
            { "type": "BOOL", "name": "INPUT_1", "defaultValue": "False" },
            { "type": "BOOL", "name": "INPUT_2", "defaultValue": "False" },
            { "type": "BOOL", "name": "INPUT_3", "defaultValue": "False" },
            ],
          },
        position: { x: 320, y: 110 },
        parentNode: parentNodeId, // Assign to parent node
      },
      {
        id: `${idOffset + 2}`,
        type: "taskNode",
        data: {
          fileName: "task.js",
          description: "Task node to perform an operation",
          inputs: [{ type: "STRING", name: "Input1", defaultValue: "" }],
          outputs: [{ type: "STRING", name: "Output1", defaultValue: "" }],
        },
        position: { x: 100, y: 390 },
        parentNode: parentNodeId, // Assign to parent node
      },
      {
        id: `${idOffset + 3}`,
        type: "errorHandlingNode",
        data: {
          alarms: [
            { name: "ERROR_1", message: "Error 1 Message", criticality: "HIGH" },
            { name: "ERROR_2", message: "Error 2 Message", criticality: "HIGH" },
            { name: "ERROR_3", message: "Error 3 Message", criticality: "HIGH" },
            { name: "ERROR_4", message: "Error 4 Message", criticality: "HIGH" },
          ],
        },
        position: { x: 360, y: 390 },
        parentNode: parentNodeId, // Assign to parent node
      },
      {
        id: `${idOffset + 4}`,
        type: "recoveryNode",
        data: { actions: ["Retry Action 1", "Retry Action 2"] },
        position: { x: 640, y: 390 },
        parentNode: parentNodeId, // Assign to parent node
      },
      {
        id: `${idOffset + 5}`,
        type: "outputNode",
        data: {
          vars: [
            { "type": "BOOL", "name": "OUTPUT_1", "defaultValue": "False" },
            { "type": "BOOL", "name": "OUTPUT_2", "defaultValue": "False" },
            { "type": "BOOL", "name": "OUTPUT_3", "defaultValue": "False" },
          
          ],
        },
        position: { x: 320, y: 700 },
        parentNode: parentNodeId, // Assign to parent node
      },
    ];
  
    const subsystemEdges = [
      { id: `e${idOffset + 1}-${idOffset + 2}`, source: `${idOffset + 1}`, target: `${idOffset + 2}`, type: "smoothstep" },
      { id: `e${idOffset + 2}-${idOffset + 3}`, source: `${idOffset + 2}`, target: `${idOffset + 3}`, type: "smoothstep" },
      { id: `e${idOffset + 2}-${idOffset + 5}`, source: `${idOffset + 2}`, target: `${idOffset + 5}`, type: "smoothstep" },
      { id: `e${idOffset + 3}-${idOffset + 4}`, source: `${idOffset + 3}`, target: `${idOffset + 4}`, type: "smoothstep" },
      { id: `e${idOffset + 4}-${idOffset + 2}`, source: `${idOffset + 4}`, target: `${idOffset + 2}`, type: "smoothstep" },
    ];
  
    return { subsystemNodes, subsystemEdges };
  };
  
  

  const onConnect = useCallback(
    (params) => {
      const newEdge = {
        ...params,
        animated: false,
        type: "smoothstep",
        markerEnd: {
          type: MarkerType.Arrow,
          color: "#000",
        },
      };
  
      // Add the new edge without label
      setEdges((prevEdges) => addEdge(newEdge, prevEdges));
    },
    [setEdges]
  );

  const handleNodeClick = (event, node) => {
    if (node.type === "group") {
      const childNodes = nodes.filter((n) => n.parentNode === node.id);
      console.log("Group Node Clicked:", node);
      console.log("Child Nodes:", childNodes);

      // Pass the selected group node and its children to the parent component
      onGroupNodeSelect(node, childNodes);
  
      setSelectedElement({
        ...node,
        children: childNodes,
      });
    } else {
      console.log("Node Clicked:", node);
      setSelectedElement(node);
    }
  };
  

  const addNewNode = (type) => {
    const defaultData = {
      fileName: `node-${nodes.length + 1}`,
      description: "Default node description",
      input: "",
      output: "",
    };
  
    // Customize node data based on type
    const nodeData = {
      inputNode: {
        ...defaultData,
        description: "Input node to receive data",
        inputs: [{ type: "STRING", name: "Input Data", defaultValue: "" }],
        outputs: [{ type: "STRING", name: "Processed Data", defaultValue: "" }],
      },
      outputNode: {
        ...defaultData,
        description: "Output node to send data",
        inputs: [{ type: "STRING", name: "Received Data", defaultValue: "" }],
        outputs: [{ type: "STRING", name: "Output Data", defaultValue: "" }],
      },
      recoveryNode: {
        ...defaultData,
        description: "Recovery node for handling errors",
        actions: ["Retry", "Notify Operator"],
        inputs: [{ type: "ERROR", name: "Error Signal", defaultValue: "" }],
        outputs: [{ type: "STATUS", name: "Recovery Status", defaultValue: "" }],
      },
      taskNode: {
        ...defaultData,
        description: "Task node to perform an operation",
        fileName: `task-${nodes.length + 1}.js`,
        inputs: [{ type: "BOOL", name: "Start Task", defaultValue: "false" }],
        outputs: [{ type: "BOOL", name: "Task Complete", defaultValue: "false" }],
      },
      errorHandlingNode: {
        ...defaultData,
        description: "Error handling node for the system",
        errorType: "Critical",
        criticalityLevel: "High",
        actions: ["Restart System", "Send Alert"],
        inputs: [{ type: "ERROR", name: "Error Input", defaultValue: "" }],
        outputs: [{ type: "STATUS", name: "Error Resolved", defaultValue: "" }],
      },
    };
  
    // Use the appropriate node data or default
    const newNode = {
      id: `${nodes.length + 1}`,
      type,
      data: nodeData[type] || defaultData,
      position: { x: Math.random() * 300, y: Math.random() * 300 }, // Random positioning
    };
  
    // Save the current state for undo functionality
    setHistory([...history, { nodes, edges }]);
  
    // Add the new node to the state
    setNodes((nds) => [...nds, newNode]);
  };
  

  const undo = () => {
    if (history.length > 0) {
      const lastState = history[history.length - 1];
      setNodes(lastState.nodes);
      setEdges(lastState.edges);
      setHistory(history.slice(0, -1));
    }
  };
  const addSubsystem = () => {
    const idOffset = nodes.length; // Ensure unique IDs for the subsystem
    const { subsystemNodes, subsystemEdges } = createSubsystem(idOffset);
  
    // Save current state to history
    setHistory([...history, { nodes, edges }]);
  
    // Add subsystem nodes and edges
    setNodes((nds) => [...nds, ...subsystemNodes]);
    setEdges((eds) => [...eds, ...subsystemEdges]);
  };
  
  const addStartNode = () => {
    const startNode = {
      id: "1",
      type: "default",
      data: { label: "Start" },
      position: { x: -40, y: -520 },
      width: 151,
      height: 40,
      selected: false,
      positionAbsolute: { x: -40, y: -520 },
      dragging: false,
    };
  
    // Save current state to history
    setHistory([...history, { nodes, edges }]);
  
    // Add the start node to the state
    setNodes((nds) => [...nds, startNode]);
  };

  


  const handleDownload = async () => {
    const flowContainer = document.querySelector(".react-flow");
    if (!flowContainer) return;
  
    // Add export styles to all nodes
    flowContainer.classList.add("export-mode");

    // Wait for styles to apply
    await new Promise((resolve) => setTimeout(resolve, 1000));
  
    // Use html2canvas to capture the diagram
    const canvas = await html2canvas(flowContainer, {
      backgroundColor: "#ffffff", // White background for export
      useCORS: true,
      scale: 2, // Increase scale for better quality
    });
    // Convert the canvas to an image
    const imageData = canvas.toDataURL("image/png");
    flowContainer.classList.remove("export-mode");
  
    // Create a new PDF instance
    const pdf = new jsPDF("landscape", "mm", "a4");
  
    // Calculate image dimensions for the PDF
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
  
    const imgWidth = canvas.width / 2; // Match canvas scale
    const imgHeight = canvas.height / 2;
  
    const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
    const scaledWidth = imgWidth * ratio;
    const scaledHeight = imgHeight * ratio;
  
    // Add the image to the PDF
    pdf.addImage(
      imageData,
      "PNG",
      (pdfWidth - scaledWidth) / 2, // Center horizontally
      (pdfHeight - scaledHeight) / 2, // Center vertically
      scaledWidth,
      scaledHeight
    );
  
    // Save the PDF
    pdf.save("flow-diagram.pdf");
  };


  const save = () => {
    const flowData = { nodes, edges };
    console.log("Saved flow data:", flowData);
    // TODO
  };

  return (
    <div style={{ width: "100%", height: "90vh", position: "relative" }}>
      <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bg="vscode.background"
          p={2}
          zIndex="10"
          boxShadow="sm"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <HStack spacing={2}>
            {/* Dropdown for selecting node types */}
            {/* <Tooltip label="Export as PDF">
              <Button
                leftIcon={<FaDownload />}
                bg="xelerit.orange"
                color="white"
                onClick={() => handleDownload()}
                aria-label="Export Flow"
                size={"sm"}
                rounded={"none"}
              >
                Export Flow as PDF
              </Button>
            </Tooltip> */}
        
            <Tooltip label="Add a subsystem">
              <Button
                leftIcon={<FaPlus />}
                bg="xelerit.lightBlue"
                color="white"
                onClick={() => addSubsystem()}
                aria-label="Add Subsystem"
                size={"sm"}
                rounded={"none"}
              >
                Add State
              </Button>
            </Tooltip>

            {/* Add Start Node */}
            <Tooltip label="Add start node">
              <Button
                leftIcon={<FaPlay />}
                bg="xelerit.lightBlue"
                color="white"
                onClick={addStartNode}
                aria-label="Add Subsystem"
                size={"sm"}
                rounded={"none"}
              >
                Add Start Node
              </Button>
            </Tooltip>

            {/* Clear All Nodes and Edges */}
            <Tooltip label="Clear all nodes and edges">
              <IconButton
                icon={<FaTrashAlt />}
                rounded={"none"}
                size={"sm"}
                onClick={clearFlow}
                aria-label="Clear Flow"
              />
            </Tooltip>

            {/* Undo Button */}
            <Tooltip label="Undo last action">
              <IconButton
                icon={<FaUndo />}
                rounded={"none"}
                size={"sm"}
                onClick={undo}
                aria-label="Undo"
              />
            </Tooltip>

            {/* Save Button */}
            <Tooltip label="Save flow">
              <IconButton
                icon={<FaSave />}
                rounded={"none"}
                size={"sm"}
                onClick={save}
                aria-label="Save"
              />
            </Tooltip>
          </HStack>
        </Box>


      {!hidden && (
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onNodeClick={handleNodeClick}
          nodeTypes={nodeTypes} 
          fitView
        >
          <Controls />
          <Background />
        </ReactFlow>
      )}
    </div>
  );
};

export default CustomNodeFlow;