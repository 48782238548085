import React, {useState, useEffect} from "react";
import ChatPanel from "../../components/panels/chatPanel";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Layout/Sidebar";
import { Flex, Box, Heading, Text, Button, useDisclosure } from "@chakra-ui/react";
import { useTheme } from "@chakra-ui/react";
import { useAuth } from "../../hooks/AuthProvider";


const BaseHome = () => {

  const { user, logOut } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [activePanel, setActivePanel] = useState("chat");
  const [panelWidths, setPanelWidths] = useState({
    textRequirements: 40,
    profile: 50,
    variables: 90,
    chat: 100,
    flowDiagram: 50,
    settings: 50,
    tasksPanel: 20,
  });
  const [currentPanelWidth, setCurrentPanelWidth] = useState(panelWidths[activePanel]);

 
  useEffect(() => {
    if (activePanel) {
      setCurrentPanelWidth(panelWidths[activePanel] || 50);
    }
  }, [activePanel, panelWidths]);
  return  (
    <Flex height="100vh">
      <Sidebar onPanelSelect={setActivePanel} />
      {activePanel && (
        <Box
          width={`${currentPanelWidth}%`}
          bg={theme.colors.vscode.background}
          color="white"
          overflowY="auto"
          boxShadow="lg"
        >
          {activePanel === "profile" && (
            <Box>
              <Heading>Welcome, {user?.username || "User"}!</Heading>
              <Text>Email: {user?.email}</Text>
            </Box>
          )}
          {activePanel === "chat" && <ChatPanel robotBrand={"estun"} />}
          {activePanel === "settings" && <SettingsPanel
              currentSettings={projectData}
              onSettingsChange={handleSettingsChange}
            />
          }
        </Box>
      )}
  
</Flex>
);
};

export default BaseHome;
