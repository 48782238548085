import React from "react";
import { Box, VStack, Divider, IconButton, Tooltip } from "@chakra-ui/react";
import {
  FaMicrochip,
  FaCogs,
  FaTable,
  FaComments,
  FaProjectDiagram,
  FaPlay,
  FaUser,
  FaCog,
  FaNotesMedical,
  FaBookReader,
  FaTasks,
} from "react-icons/fa";
import { useTheme } from "@chakra-ui/react";

const Sidebar = ({ onPanelSelect }) => {
  const theme = useTheme();

  // Define the panels for the top and bottom sections as arrays
  const topPanels = [
    { label: "textRequirements", icon: FaBookReader, id: "textRequirements", hoverColor: theme.colors.xelerit.mediumBlue },
    { label: "Variables", icon: FaTable, id: "variables", hoverColor: theme.colors.xelerit.mediumBlue },
    // { label: "Flow Diagram", icon: FaProjectDiagram, id: "flowDiagram", hoverColor: theme.colors.xelerit.lightBlue },
    { label: "Chat", icon: FaComments, id: "chat", hoverColor: theme.colors.vscode.primaryBlue },
  ];

  const bottomPanels = [
    { label: "Profile", icon: FaUser, id: "profile", hoverColor: theme.colors.xelerit.orange },
    { label: "Settings", icon: FaCog, id: "settings", hoverColor: theme.colors.vscode.errorRed },
  ];

  // Reusable function to render panel items
  const renderPanelItems = (panels) =>
    panels.map((panel) => (
      <Tooltip key={panel.id} label={panel.label} placement="right">
        <IconButton
          icon={<panel.icon />}
          aria-label={panel.id}
          variant="ghost"
          size="lg"
          color={theme.colors.vscode.foreground}
          _hover={{ bg: panel.hoverColor }}
          onClick={() => onPanelSelect(panel.id)}
        />
      </Tooltip>
    ));

  return (
    <Box
      as="nav"
      bg={theme.colors.vscode.sidebar}
      width="60px"
      height="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      paddingY={4}
      justifyContent="space-between"
    >
      {/* Top Section */}
      <VStack spacing={4}>{renderPanelItems(topPanels)}</VStack>

      {/* Divider */}
      <Divider orientation="horizontal" borderColor="gray.600" />

      {/* Bottom Section */}
      <VStack spacing={4}>{renderPanelItems(bottomPanels)}</VStack>
    </Box>
  );
};

export default Sidebar;
