import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./hooks/AuthProvider";

const ProtectedRoute = ({ children, roles = [] }) => {
  const { user } = useAuth();

  // Check if user is authenticated
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  // Check if user's role matches allowed roles
  if (roles.length > 0 && !roles.includes(user.role)) {
    return <Navigate to="/unauthorized" replace />; // Redirect to unauthorized page
  }

  return children;
};

export default ProtectedRoute;

