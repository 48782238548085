import { useContext, createContext, useState } from "react";
import{Navigate } from 'react-router-dom';
import { loginAction as apiLoginAction } from "../api/users"; // Import loginAction from api/auth.js

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("site") || "");
 
  const loginAction = async (credentials) => {
    try {
      const { token, user } = await apiLoginAction(credentials); // Assume user object includes role
  
      // Save user and token in state
      setUser(user);
      setToken(token);
  
      // Persist token and user in localStorage
      localStorage.setItem("site", token);
      localStorage.setItem("user", JSON.stringify(user));
  
      console.log("Login successful:", user);
    } catch (error) {
      console.error("Login error:", error.message);
      throw error;
    }
  };
  

  const logOut = () => {
    setUser(null);
    setToken("");
    localStorage.removeItem("site");
    localStorage.removeItem("user");
    <Navigate to="/login" />;
  };

  return (
    <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );

};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};