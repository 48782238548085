import React, { useState, useRef, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { message_and_response_stream, isChatInitialized } from "../../api/message_chat_bot";
import { Flex, Box } from "@chakra-ui/react";
import MessageInput from "../Chat/MessageInput";
import InitialMessagePrompt from "../Chat/InitialMessagePrompt";
import MessageList from "../Chat/MessageList";

const ChatPanel = ({ robotBrand, onQuestionAsked, userId }) => {
  const [conversationId, setConversationId] = useState(uuidv4());
  const [isFirstMessageSent, setIsFirstMessageSent] = useState(false);
  const [predefinedQuestions, setPredefinedQuestions] = useState([]);
  const text = "Start by typing your question or choose one below!";
  const letters = text.split("");

  const [messages, setMessages] = useState([]);
  const [userMessage, setUserMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const chatContainerRef = useRef(null);

  // Reset conversationId when robotBrand changes
  useEffect(() => {
    setConversationId(uuidv4());
    setIsFirstMessageSent(false); // Reset the state for a new conversation
    setMessages([]); // Clear previous messages
  }, [robotBrand]);

  useEffect(() => {
    switch (robotBrand) {
      case "estun":
        setPredefinedQuestions([
          "What does MovJ do?",
          "What is APOS?",
          "What are the parameters of MovCircle?",
          "How can I integrate you into my project?",
        ]);
        break;
      case "abb":
        setPredefinedQuestions([
          "How do I calibrate the robot?",
          "What is the maximum payload?",
          "How do I update the firmware?",
          "Can you work with other robots?",
        ]);
        break;
      default:
        setPredefinedQuestions([
          "What does MovJ do?",
          "What is APOS?",
          "What are the parameters of MovCircle?",
          "How can I integrate you into my project?",
        ]);
        break;
    }
  }, [robotBrand]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSendMessage = async (message) => {
    const isChatInit = await isChatInitialized(robotBrand);
    if (!isChatInit) return;

    console.log(conversationId);

    const msg = message || userMessage;
    if (!msg.trim()) return;

    const newUserMessage = { sender: "user", content: msg };
    setMessages((prev) => [...prev, newUserMessage]);

    if (!isFirstMessageSent) {
      setIsFirstMessageSent(true);
    }

    const payload = {
      conversation_id: conversationId,
      question: msg,
      top_k: 5,
      robot_brand_id: robotBrand,
      user_id : userId,
    };

    const botMessagePlaceholder = { sender: "bot", content: "", complete: false };
    setMessages((prev) => [...prev, botMessagePlaceholder]);

    try {
      setIsLoading(true);

      await message_and_response_stream(payload, (chunk) => {
        setMessages((prev) => {
          const updatedMessages = [...prev];
          const botMessageIndex = updatedMessages.findIndex(
            (msg) => msg.sender === "bot" && !msg.complete
          );
          if (botMessageIndex !== -1) {
            updatedMessages[botMessageIndex].content += chunk;
          }
          return updatedMessages;
        });
      });

      setMessages((prev) =>
        prev.map((msg) =>
          msg.sender === "bot" && !msg.complete ? { ...msg, complete: true } : msg
        )
      );
    } catch (error) {
      console.error("Error during streaming:", error);
      setMessages((prev) => [
        ...prev.slice(0, -1),
        { sender: "bot", content: "Sorry, something went wrong. Please try again later.", complete: true },
      ]);
    } finally {
      setIsLoading(false);
      setUserMessage("");
      onQuestionAsked();
    }
  };

  const handleInputChange = (e) => setUserMessage(e.target.value);

  if (!isFirstMessageSent) {
    return (
      <Flex justify="center" align="center" height="100%">
        <InitialMessagePrompt
          letters={letters}
          userMessage={userMessage}
          setUserMessage={setUserMessage}
          handleSendMessage={handleSendMessage}
          isLoading={isLoading}
          predefinedQuestions={predefinedQuestions}
        />
      </Flex>
    );
  }

  return (
    <Flex height="100%" justify="center" align="center">
      <Box width={{ base: "100%", md: "700px", lg: "1200px" }} position="relative">
        <Flex
          height="90vh"
          maxHeight="90vh"
          overflowY="auto"
          borderRadius="md"
          shadow="inner"
          ref={chatContainerRef}
          direction="column"
          mb={10}
          paddingBottom="80px" // Add space equal to the height of the MessageInput
        >
          <MessageList messages={messages} isLoading={isLoading} />
        </Flex>

        <Box
          position="absolute"
          bottom="25px"
          left="50%"
          transform="translateX(-50%)"
          width="100%"
          padding="10px"
          zIndex="1000"
        >
          <MessageInput
            userMessage={userMessage}
            setUserMessage={setUserMessage}
            handleSendMessage={handleSendMessage}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </Flex>
  );
};

export default ChatPanel;

