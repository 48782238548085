import React from "react";
import { Flex, Textarea, Button, Box } from "@chakra-ui/react";

const MessageInput = ({ userMessage, setUserMessage, handleSendMessage, isLoading }) => {
  const handleKeyDown = (e) => {
    // Check if Shift + Enter is pressed
    if (e.key === "Enter" && !e.shiftKey && !isLoading) {
      e.preventDefault(); // Prevent default Enter behavior (new line)
      handleSendMessage();
    }
  };

  return (
    <Box p={3} borderRadius="xl" bg="vscode.sidebar" shadow="lg">
      <Flex align="center">
        <Textarea
          placeholder="Type your message... (Shift + Enter to break line)"
          value={userMessage}
          onChange={(e) => setUserMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          isDisabled={isLoading}
          resize="none" // Prevent manual resizing
          rows={1} // Default number of rows
          focusBorderColor="xelerit.lightBlue" // Focus border color
        />
        <Button
          ml={1}
          bg="xelerit.lightBlue"
          color="white"
          onClick={() => handleSendMessage()}
          isLoading={isLoading}
        >
          Send
        </Button>
      </Flex>
    </Box>
  );
};

export default MessageInput;
