import React, {useState} from 'react';
import { Box, Heading, Flex, Text, Textarea, Button, Center, Spinner} from '@chakra-ui/react';
import CustomNodeFlow from '../Diagram/ReactFlow';
import { initialNodes, initialEdges } from '../Diagram/initialData';
import ReactFlow, {
  useNodesState,
  useEdgesState,
} from 'reactflow';
import { FaPlay } from 'react-icons/fa';


const FlowDiagramPanel = ({ nodes, edges, setNodes, setEdges, onNodesChange, onEdgesChange , generateFlowDiagram, loading, onNodeSelect, onGroupNodeSelect, nodesHidden}) => {
  
  const handleNodeSelect = (node) => {
    onNodeSelect(node); // Save the clicked node's data
    console.log("Selected node:", node);
  };

  const handleGroupNodeSelect = (node, childNodes) => { 
    onGroupNodeSelect(node, childNodes); // Save the clicked node's data
    console.log("Selected group node:", node);
  }


    return (
      <Box padding={2}>
        <Box
          marginTop={2}
          width="100%"
          height="100%"
          bg="vscode.background"
          borderRadius="md"
          shadow="inner"
        >
          <Flex justify="space-between" align="center" mb={4}>
          </Flex>
          <Box w="100%" h="100%" borderWidth="1px" borderRadius="lg" bg="brand.50">
          {loading ? (
          // Show spinner while loading
          <Center w="100%" h="100%">
            <Spinner size="xl" color="xelerit.orange" />
          </Center>
        ) : (
          <CustomNodeFlow
            nodes={nodes}
            edges={edges}
            setNodes={setNodes}
            setEdges={setEdges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onNodeSelect={handleNodeSelect}
            onGroupNodeSelect={handleGroupNodeSelect}
          />
        )}
          </Box>
        </Box>
      </Box>
    );
  };
  
export default FlowDiagramPanel;