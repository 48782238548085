import { MarkerType } from "reactflow";


export const initialNodes = [
  {
    id: "1",
    type: "default",
    data: { label: "Start" },
    position: { x: -40, y: -520 },
    width: 151,
    height: 40,
    selected: false,
    positionAbsolute: { x: -40, y: -520 },
    dragging: false,
  },
  {
    id: "1-parent",
    type: "group",
    data: { label: "STATE_X" },
    position: { x: -420, y: -380 },
    selected: false,
    positionAbsolute: { x: -420, y: -380 },
    dragging: false,
  },
  {
    id: "2",
    type: "inputNode",
    data: {
      vars: [
      { "type": "BOOL", "name": "INPUT_1", "defaultValue": "False" },
      { "type": "BOOL", "name": "INPUT_2", "defaultValue": "False" },
      { "type": "BOOL", "name": "INPUT_3", "defaultValue": "False" },
      ],
    },
    position: { x: 320, y: 100 },
    parentNode: "1-parent",
    
  },
  {
    id: "3",
    type: "taskNode",
    data: {
      fileName: "File Name",
      description: `Description of the task`,
    },
    position: { x: 120, y: 300 },
    parentNode: "1-parent",
    
  },
  {
    id: "4",
    type: "errorHandlingNode",
    data: {
      alarms: [
        { name: "ERROR_1", message: "Error 1 Message", criticality: "HIGH" },
        { name: "ERROR_2", message: "Error 2 Message", criticality: "HIGH" },
        { name: "ERROR_3", message: "Error 3 Message", criticality: "HIGH" },
        { name: "ERROR_4", message: "Error 4 Message", criticality: "HIGH" },
      ],
    },
    position: { x: 380, y: 340 },
    parentNode: "1-parent",
    width: 220,
    height: 119,
  },
  {
    id: "5",
    type: "recoveryNode",
    data: { actions: ["Retry Action 1", "Retry Action 2"] },
    position: { x: 620, y: 340 },
    parentNode: "1-parent",
    width: 220,
    height: 119,
  },
  {
    id: "6",
    type: "outputNode",
    data: {
      vars: [
        { "type": "BOOL", "name": "OUTPUT_1", "defaultValue": "False" },
        { "type": "BOOL", "name": "OUTPUT_2", "defaultValue": "False" },
        { "type": "BOOL", "name": "OUTPUT_3", "defaultValue": "False" },
      
      ],
    },
    position: { x: 300, y: 660 },
    parentNode: "1-parent",
    width: 200,
    height: 91,
  },
];

export const intialEdges = [
  { id: "e2-3", source: "2", target: "3", type: "smoothstep" },
  { id: "e3-4", source: "3", target: "4", type: "smoothstep" },
  { id: "e3-6", source: "3", target: "6", type: "smoothstep" },
  { id: "e4-5", source: "4", target: "5", type: "smoothstep" },
  { id: "e5-3", source: "5", target: "3", type: "smoothstep" },
  {
    source: "1",
    sourceHandle: null,
    target: "1-parent",
    targetHandle: "group-input",
    animated: false,
    type: "smoothstep",
    markerEnd: { type: "arrow", color: "#000" },
    id: "reactflow__edge-1-1-parentgroup-input",
  },
];