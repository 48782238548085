import { BASE_URL } from '../config';

const apiClient = {
  async get(endpoint) {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    if (!response.ok) throw new Error(`Error: ${response.status}`);
    return response.json();
  },
  
  async post(endpoint, data, isFileResponse = false) {
    const options = {
      method: 'POST',
      body: data instanceof FormData ? data : JSON.stringify(data),
    };

    // Only set headers for JSON data; FormData will set its own headers
    if (!(data instanceof FormData)) {
      options.headers = { 'Content-Type': 'application/json' };
    }

    const response = await fetch(`${BASE_URL}${endpoint}`, options);

    if (!response.ok) throw new Error(`Error: ${response.status}`);

    // Check if the response is a file
    if (isFileResponse) {
      return response.blob(); // Return the file as a Blob
    }

    return response.json();
  },

  async put(endpoint, data) {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
    if (!response.ok) throw new Error(`Error: ${response.status}`);
    return response.json();
  },

  async delete(endpoint) {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    });
    if (!response.ok) throw new Error(`Error: ${response.status}`);
    return response.json();
  },

  /**
   * Streaming POST method for APIs that stream responses.
   * @param {string} endpoint - The API endpoint.
   * @param {Object} data - The request payload.
   * @param {function} onChunk - Callback for processing each chunk of streamed data.
   */
  async postStream(endpoint, data, onChunk) {
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };

    const response = await fetch(`${BASE_URL}${endpoint}`, options);

    if (!response.ok) throw new Error(`Error: ${response.status}`);

    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');
    let done = false;

    while (!done) {
      const { value, done: readerDone } = await reader.read();
      done = readerDone;

      if (value) {
        const chunk = decoder.decode(value, { stream: !done });
        onChunk(chunk);
      }
    }
  },

};
export default apiClient;