import apiClient from "./apiClient";

/**
 * Upload a PLC file to the backend and process it.
 * @param {File} file - The file to upload.
 * @param {string} plcManufacturer - The PLC manufacturer.
 * @param {string} robotBrand - The robot brand.
 * @returns {Object} - Returns the processed variables as JSON.
 */
export const upload_plc_vars_file = async (file, plcManufacturer, robotBrand) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("plc_manufacturer", plcManufacturer);
  formData.append("robot_brand", robotBrand);

  const response = await apiClient.post("/variables/upload-plc-vars", formData, false);
  return response; 
};

/**
 * Download robot-specific variables as a file.
 * @param {Object} payload - The variables and metadata to process.
 * @returns {Blob} - The file blob to download.
 */
export const download_robot_vars_file = async ({ variables, robotBrand }) => {
    const payload = {
      variables,
      robot_brand: robotBrand, // Only keep robot brand
    };
  
    const response = await apiClient.post("/variables/download-robot-vars", payload, true);
  
    return response; 
    
};


export const checkServiceAvailability = async (plcManufacturer, robotBrand) => {
  try {
    const response = await apiClient.post('/variables/check-service-availability', {
      plc_manufacturer: plcManufacturer,
      robot_brand: robotBrand,
    });
    return response;
  } catch (error) {
    console.error("Error checking service availability:", error);
    throw error;
  }
};
