import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import AdminDashboard from "./pages/admin/AdminDashboard";
import PremiumHome from "./pages/premium/PremiumHome";
import BaseHome from "./pages/base/BaseHome";

import AuthProvider, { useAuth } from "./hooks/AuthProvider";
import ProtectedRoute from "./ProtectedRoute";

const AppRoutes = () => {
  const isAuthenticated = localStorage.getItem('token'); // Example: Check token for authentication
  return (
    <Router>
    <AuthProvider>
      
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/unauthorized"  />

          {/* Protected Routes */}
          <Route
            path="/admin-dashboard"
            element={
              <ProtectedRoute roles={["admin"]}>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/premium-home"
            element={
              <ProtectedRoute roles={["premium"]}>
                <PremiumHome />
              </ProtectedRoute>
            }
          />
          <Route
            path="/base-home"
            element={
              <ProtectedRoute roles={["base"]}>
                <BaseHome />
              </ProtectedRoute>
            }
          />

          {/* Default Route */}
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
     
    </AuthProvider>
    </Router>
  );
};

export default AppRoutes;


