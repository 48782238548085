import React, { useState } from "react";
import AppRoutes from "./routes";


const App = () => {


  return (
    <>
      <AppRoutes />
    </>
  );
};

export default App;
