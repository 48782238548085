import React, { useEffect, useState } from "react";
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Select,
  Flex,
  VStack
} from "@chakra-ui/react";
import { getAllProjects, createProject, getProjectDetails } from "../api/projects";
import { useAuth } from "../hooks/AuthProvider"; // Import Auth Hook

const ProjectPopup = ({ isOpen, onClose, onSelectProject }) => {
  const { user } = useAuth(); // Logged-in user
  const [projects, setProjects] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [newProject, setNewProject] = useState({
    name: "",
    description: "",
    plc_brand: "",
    robot_brand: "",
    user_id: user?.id || "", // Default to logged-in user
  });

  useEffect(() => {
    if (isOpen) {
      fetchProjects();
    }
  }, [isOpen]);

  // Fetch projects for the logged-in user
  const fetchProjects = async () => {
    try {
      const response = await getAllProjects();
      //const userProjects = response.filter((project) => project.user_id === user.id); // Filter by user
      const userProjects = response; // Filter by user
      console.log("all projects:", response);
      console.log("logged in user:", user);
      console.log("User projects:", userProjects);
      setProjects(userProjects);
    } catch (error) {
      console.error("Failed to fetch projects:", error);
      setProjects([]); // Set to empty array in case of error
    }
  };

  const fetchProjectDetails = async (projectId) => {
    try {
      // Fetch project details including flow diagram and nodes
      const response = await getProjectDetails(projectId);
      console.log("Fetched Project Details:", response);

      const { plc_brand, robot_brand, flow_diagram } = response;
        console.log("PLC Brand:", plc_brand);
        console.log("Robot Brand:", robot_brand);
        console.log("Flow Diagram:", flow_diagram);
        
  
      
  
      // Update nodes and edges for the flow diagram
      const { task_nodes: nodes, edges } = flow_diagram || {};
        console.log("Nodes:", nodes);
        console.log("Edges:", edges);
        
  
      
    } catch (error) {
      console.error("Error fetching project details:", error);
    }
  };
  

  // Handle creating a new project
  const handleCreate = async () => {
    try {
      const createdProject = await createProject(newProject);
      console.log("Created Project:", createdProject);
      fetchProjects(); // Refresh project list
      setIsCreateModalOpen(false); // Close modal
      setNewProject({ name: "", description: "", plc_brand: "", robot_brand: "", user_id: user?.id }); // Reset form
      onSelectProject(createdProject.id); // Open the new project
    } catch (error) {
      console.error("Failed to create project:", error);
    }
  };

  // Handle selecting an existing project
  const handleSelectProject = (projectId) => {
    onSelectProject(projectId); // Pass selected project ID to parent component
    fetchProjectDetails(projectId);
    onClose(); // Close the popup
  };

  return (
    <>
      {/* Main Project Popup */}
      <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
        <ModalOverlay bg="blackAlpha.600" backdropFilter="blur(5px)" />
        <ModalContent borderRadius="md" bg="vscode.sidebar" p={4}>
          <ModalHeader fontSize="2xl" fontWeight="bold" textAlign="center">
            Your Projects
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
            <Button
              mb={4}
              backgroundColor="xelerit.orange"
              color={"white"}
              onClick={() => setIsCreateModalOpen(true)}
            >
              Add New Project
            </Button>
            <Text fontSize="lg" textAlign="center" mb={4}>
                or
            </Text>
            {projects.length > 0 ? (
              <VStack>
                {projects.map((project) => (
                  <Box
                    key={project.id}
                    p={4}
                    
                    borderRadius="md"
                    bg="vscode.foreground"
                    _hover={{ bg: "xelerit.orange", cursor: "pointer" }}
                    onClick={() => handleSelectProject(project.id)} // Select project
                    width={"100%"}
                  >
                    <Text fontSize="md" color="vscode.sidebar">
                      {project.name}
                    </Text>
                    <Text fontSize="xs" color="gray.600">
                      {project.description || "No description provided"}
                    </Text>
                    <Text fontSize="xs" color="gray.500">
                      Robot Brand: {project.robot_brand || "N/A"}
                    </Text>
                  </Box>
                ))}
              </VStack>
            ) : (
              <Text fontSize="lg" textAlign="center" color="gray.600">
                No projects available.
              </Text>
            )}
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button colorScheme="gray" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Create New Project Modal */}
      <Modal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        size="md"
        isCentered
      >
        <ModalOverlay bg="blackAlpha.600" backdropFilter="blur(5px)" />
        <ModalContent borderRadius="md" bg="vscode.sidebar" p={4}>
          <ModalHeader>Create New Project</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel>Project Name</FormLabel>
              <Input
                value={newProject.name}
                onChange={(e) =>
                  setNewProject((prev) => ({ ...prev, name: e.target.value }))
                }
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Description</FormLabel>
              <Input
                value={newProject.description}
                onChange={(e) =>
                  setNewProject((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>PLC Manufacturer</FormLabel>
              <Select
                placeholder="Select PLC Manufacturer"
                value={newProject.plc_brand}
                onChange={(e) =>
                  setNewProject((prev) => ({
                    ...prev,
                    plc_brand: e.target.value,
                  }))
                }
              >
                <option value="siemens">Siemens</option>
                
              </Select>
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Robot ID</FormLabel>
              <Select
                placeholder="Select Robot Brand"
                value={newProject.robot_brand}
                onChange={(e) =>
                    setNewProject((prev) => ({
                      ...prev,
                      robot_brand: e.target.value,
                    }))
                  }
              >
                <option value="estun">Estun</option>
                <option value="abb">ABB</option>
                <option value="kuka">KUKA</option>
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              backgroundColor="xelerit.orange"
              color="white"
              mr={3}
              onClick={handleCreate}
              isDisabled={!newProject.name || !newProject.description ||  !newProject.plc_brand || !newProject.robot_brand} // Validate required fields
            >
              Create and Open
            </Button>
            <Button onClick={() => setIsCreateModalOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProjectPopup;
