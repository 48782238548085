import React from 'react';
import { Box, Container, Text, Link, Stack } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Box as="footer" bg="xelerit.mediumBlue" color="gray.200">
      <Container maxW="container.xl">
        <Stack
          direction={{ base: 'column', sm: 'row' }}
          justify="space-between"
          align="center"
        >
          {/* Company Name */}
          <Text fontSize="sm">© {new Date().getFullYear()} Xelerit-Robotics. All rights reserved.</Text>
          
          {/* Links */}
          <Stack direction="row" spacing={4}>
            <Link href="/terms" fontSize="sm" _hover={{ color: 'xelerit.lightBlue' }}>
              Terms of Use
            </Link>
            <Link href="/privacy" fontSize="sm" _hover={{ color: 'xelerit.lightBlue' }}>
              Privacy Policy
            </Link>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;
