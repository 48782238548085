import { extendTheme } from "@chakra-ui/react";
import {  MultiSelectTheme } from 'chakra-multiselect'
const colors = {
  vscode: {
    background: "#1e1e1e", // Editor background
    foreground: "#d4d4d4", // Editor foreground
    primaryBlue: "#569cd6",    // Primary blue
    secondaryYellow: "#dcdcaa",  // Secondary yellow
    errorRed: "#f44747",      // Error red
    successGreen: "#608b4e",    // Success green
    accentPurple: "#c586c0",     // Accent purple
    sidebar: "#252526",    // Sidebar background
    terminal: "#1e1e1e",   // Terminal background
  },

  xelerit: {
    background: "#19191a",
    grey: '#E9EEF2',   // grey
    lightBlue: '#08BEF7',  //light blue
    mediumBlue: '#0A81B4', // medium blue
    darkBlue: '#081F2D',  // Dark blue
    orange: '#FF8500',  // orange

  }
};

const styles = {
  global: {
    "html, body": {
      backgroundColor: colors.vscode.background,
      color: colors.vscode.foreground,
      fontFamily: "Fira Code, monospace", // Font similar to VSCode's
    },
    a: {
      color: colors.vscode.primary,
      _hover: {
        textDecoration: "underline",
      },
    },
  },
};

const theme = extendTheme({
  colors,
  styles,
  components: {
    MultiSelect: MultiSelectTheme
  }
});

export default theme;
