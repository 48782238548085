import { Heading, Flex, Button } from "@chakra-ui/react";
import React, { memo } from "react";
import { Handle } from "reactflow";

const GroupNode = ({ data, isConnectable }) => {
  return (
    <Flex
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        color: "white",
        padding: "10px",
        borderRadius: "5px",
        width: "900px",
        height: "900px",
        display: "flex",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        justifyContent: "center", // Center horizontally
        cursor: "pointer",
        position: "absolute",
        alignItems: "center", // Center vertically
      }}
    >
      <Button
        size="lg"
        width={"200px"}
        backgroundColor="white"
        color="black"
        position="absolute"
        top="-10px"
        left="-10px"
        
      >
        {data.label}
      </Button>
      <Handle
        type="source"
        position="bottom"
        id="group-output"
        isConnectable={isConnectable}
        style={{ width: '20px', height: '20px'}}
      />
      <Handle
        type="target"
        position="top"
        id="group-input"
        isConnectable={isConnectable}
        style={{ width: '20px', height: '20px' }}
      />
    </Flex>
  );
};

export default memo(GroupNode);
