import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  VStack,
  HStack,
  Input,
  Select,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Divider,
  Textarea,
  Flex,
  IconButton,
  Collapse,
  Icon,
} from "@chakra-ui/react";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import { generateTaskCode } from '../../api/state';
import { fetchSkills } from "../../api/state";

const VARIABLE_TYPES = [
  "BOOL", "INT", "REAL", "STRING", "BOOLONEARRAY", "INTONEARRAY", "REALONEARRAY",
  "APOS", "CPOS", "TOOL", "USERCOOR", "AI", "AO", "DI", "DO", "SimAI", "SimAO",
  "SimDI", "SimDO", "RET", "AREA", "POLYHEDRON", "CLOCK", "PLCREAL", "SOCKET",
  "PALLET", "WEAVE", "SPEED", "ZONE", "PAYLOAD", "EXTTCP", "POSITIONER",
  "SYNCOORD", "LsScale", "LsThresh", "PLCREAL", "PLCBOOL", "PLCINT", "PLCDOMNT",
  "APOSARRAY", "CPOSARRAY", "INTERRUPT",
];

const UNIQUE_VARIABLE_TYPES = Array.from(new Set(VARIABLE_TYPES));

const TaskDetailsPanel = ({ nodeData, onUpdate, nodes }) => {
  const [fileName, setFileName] = useState(nodeData?.data?.fileName || "Untitled");
  const [description, setDescription] = useState(nodeData?.data?.description || "Short task description");
  const [inputs, setInputs] = useState(nodeData?.data?.inputs || []);
  const [outputs, setOutputs] = useState(nodeData?.data?.outputs || []);
  const [programVars, setProgramVars] = useState(nodeData?.data?.programVars || []);
  const [skills, setSkills] = useState(nodeData?.data?.skills || []);
  const [expandedSection, setExpandedSection] = useState("");
  const [isSkillsModalOpen, setIsSkillsModalOpen] = useState(false);
  const [skillsLibrary, setSkillsLibrary] = useState([]);

  useEffect(() => {
    if (!nodeData || !nodeData.id) {
      console.error("TaskDetailsPanel received invalid nodeData:", nodeData);
    } else {
      setFileName(nodeData.data.fileName || "Untitled");
      setDescription(nodeData.data.description || "Short task description");
      setInputs(nodeData.data.inputs || []);
      setOutputs(nodeData.data.outputs || []);
      setProgramVars(nodeData.data.programVars || []);
      setSkills(nodeData.data.skills || []);
    }
  }, [nodeData]);

  const handleUpdate = () => {
    if (onUpdate) {
      onUpdate({
        id: nodeData.id, // Ensure the node ID is included
        data: {
          fileName,
          description,
          inputs,
          outputs,
          programVars,
          skills,
        },
        updatedConnections: [...inputs, ...outputs], // Include updated connections
      });
    }
  };

  useEffect(() => {
    // Fetch skills from the backend using fetchSkills
    const loadSkills = async () => {
      try {
        const response = await fetchSkills();
        const skillIds = response.map((skill) => skill);
        setSkillsLibrary(skillIds);
      } catch (error) {
        console.error("Error fetching skills:", error);
      }
    };

    loadSkills();
  }, []);

  const openSkillsModal = () => setIsSkillsModalOpen(true);
  const closeSkillsModal = () => setIsSkillsModalOpen(false);

  const addSkillFromLibrary = (skill) => {
    if (!skills.includes(skill.id)) {
      setSkills([...skills, skill.id]); // Save the skill ID in the task
    }
    closeSkillsModal();
  };

  const toggleSection = (section) => {
    setExpandedSection((prev) => (prev === section ? "" : section));
  };

  const addVariable = (setFunction, variables) => {
    setFunction([...variables, { type: "", name: "", defaultValue: "", connectTo: "" }]);
  };

  const removeVariable = (index, setFunction, variables) => {
    setFunction(variables.filter((_, i) => i !== index));
  };

  const handleGenerateCode = async () => {
    try {
      const payload = {
        task: {
          id: nodeData.id,
          data: {
            fileName,
            description,
            inputs,
            outputs,
            programVars,
            skills,
          },
        },
        language: "abb", // Replace with dynamic language selection if required
      };

      console.log("Payload before sending:", payload);

      const response = await generateTaskCode(payload); // Use generateTaskCode function
      console.log(response);
    } catch (error) {
      console.error("Error generating code:", error);
    }
  };

  const renderVariable = (variables, setFunction, category) =>
    variables.map((variable, index) => {
      return (
        <HStack key={index} spacing={2} align="start">
          <Select
            placeholder="Type"
            value={variable.type}
            onChange={(e) => {
              const updatedVariables = [...variables];
              updatedVariables[index].type = e.target.value;
              setFunction(updatedVariables);
            }}
            size="sm"
          >
            {UNIQUE_VARIABLE_TYPES.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Select>
          <Input
            placeholder="Name"
            value={variable.name}
            onChange={(e) => {
              const updatedVariables = [...variables];
              updatedVariables[index].name = e.target.value;
              setFunction(updatedVariables);
            }}
            size="sm"
          />
          <Input
            placeholder="Default Value"
            value={variable.defaultValue}
            onChange={(e) => {
              const updatedVariables = [...variables];
              updatedVariables[index].defaultValue = e.target.value;
              setFunction(updatedVariables);
            }}
            size="sm"
          />
          {category !== "programVars" && (
            <Select
              placeholder="Connect to Node"
              value={variable.connectTo}
              onChange={(e) => {
                const updatedVariables = [...variables];
                updatedVariables[index].connectTo = e.target.value;
                setFunction(updatedVariables);
              }}
              size="sm"
            >
              {nodes
                .filter((node) => node.id !== nodeData.id) // Exclude the current node
                .map((node) => (
                  <option key={node.id} value={node.id}>
                    {node.data.fileName || `Node ${node.id}`}
                  </option>
                ))}
            </Select>
          )}
          <IconButton
            icon={<FiTrash2 />}
            size="xs"
            colorScheme="red"
            onClick={() => removeVariable(index, setFunction, variables)}
          />
        </HStack>
      );
    });

  return (
    <Box p={4} bg="vscode.background" borderRadius="md" fontSize="sm" boxShadow="sm">
      {/* File Name */}
      <HStack align="center" mb={4}>
        <Text fontWeight="bold">Filename:</Text>
        <Input
          value={fileName}
          onChange={(e) => setFileName(e.target.value)}
          size="sm"
        />
      </HStack>

      {/* Description */}
      <HStack align="start" mb={4}>
        <Text fontWeight="bold">Description:</Text>
        <Textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          size="sm"
          resize="vertical"
          minHeight="80px"
        />
      </HStack>

      {/* Collapsible Inputs Section */}
      <Box mb={4}>
        <Flex justify="space-between" align="center" onClick={() => toggleSection("inputs")}>
          <Text fontWeight="bold">Inputs</Text>
          <IconButton
            size="xs"
            icon={expandedSection === "inputs" ? <FaAngleDown /> : <FaAngleRight />}
          />
        </Flex>
        <Collapse in={expandedSection === "inputs"}>
          <VStack spacing={2} mt={2}>
            {renderVariable(inputs, setInputs, "inputs")}
            <Button
              size="xs"
              backgroundColor="vscode.foreground"
              onClick={() => addVariable(setInputs, inputs)}
            >
              <FiPlus /> Add Input
            </Button>
          </VStack>
        </Collapse>
      </Box>

      {/* Collapsible Outputs Section */}
      <Box mb={4}>
        <Flex justify="space-between" align="center" onClick={() => toggleSection("outputs")}>
          <Text fontWeight="bold">Outputs</Text>
          <IconButton
            size="xs"
            icon={expandedSection === "outputs" ? <FaAngleDown /> : <FaAngleRight />}
          />
        </Flex>
        <Collapse in={expandedSection === "outputs"}>
          <VStack spacing={2} mt={2}>
            {renderVariable(outputs, setOutputs, "outputs")}
            <Button
              size="xs"
              backgroundColor="vscode.foreground"
              onClick={() => addVariable(setOutputs, outputs)}
            >
              <FiPlus /> Add Output
            </Button>
          </VStack>
        </Collapse>
      </Box>

      {/* Collapsible Program Variables Section */}
      <Box mb={4}>
        <Flex justify="space-between" align="center" onClick={() => toggleSection("programVars")}>
          <Text fontWeight="bold">Program Variables</Text>
          <IconButton
            size="xs"
            icon={expandedSection === "programVars" ? <FaAngleDown /> : <FaAngleRight />}
          />
        </Flex>
        <Collapse in={expandedSection === "programVars"}>
          <VStack spacing={2} mt={2}>
            {renderVariable(programVars, setProgramVars, "programVars")}
            <Button
              size="xs"
              backgroundColor="vscode.foreground"
              onClick={() => addVariable(setProgramVars, programVars)}
            >
              <FiPlus /> Add Program Variable
            </Button>
          </VStack>
        </Collapse>
      </Box>
      <Divider my={4} />

      {/* Skills Section */}
      <Box mb={4}>
        <Text fontWeight="bold" mb={2}>Skills:</Text>
        <VStack align="stretch">
          {skills.map((skill, index) => (
            <HStack key={index} spacing={2}>
              <Text>{skill}</Text>
              <Button
                size="xs"
                colorScheme="red"
                onClick={() => setSkills(skills.filter((_, i) => i !== index))}
              >
                <Icon as={FiTrash2} />
              </Button>
            </HStack>
          ))}
          <Button size="xs" color="vscode.foreground" width="100px" onClick={openSkillsModal}>
            <Icon as={FiPlus} mr={1} /> Add Skill
          </Button>
        </VStack>
      </Box>
      <Divider my={4} />

      {/* Save/Update Button */}
      <Button backgroundColor="vscode.foreground" color="black" size="sm" onClick={handleUpdate} w="full" mt={4}>
        Save updates
      </Button>
      <Button backgroundColor="xelerit.orange" color="white" size="sm" onClick={handleGenerateCode} w="full" mt={4}>
        Generate Code
      </Button>

      {/* Skills Modal */}
      <Modal isOpen={isSkillsModalOpen} onClose={closeSkillsModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select a Skill</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="stretch">
              {skillsLibrary?.map((skill) => (
                <Button
                  key={skill.id}
                  onClick={() => addSkillFromLibrary(skill)}
                  size="sm"
                  variant="outline"
                >
                  {skill.id}
                </Button>
              ))}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button size="xs" onClick={closeSkillsModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default TaskDetailsPanel;