import apiClient from './apiClient';

export const sendFeedback = async (data) => {
    const response = await apiClient.post('/feedback', data );
    return response;
  };

export const sendRobotRequest = async (data) => {
    const response = await apiClient.post('/feedback/robot-request', data );
    return response;
  }
