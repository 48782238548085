import React, { useState } from "react";
import { chakra } from "@chakra-ui/react";
import {
  Box,
  Flex,
  Stack,
  Avatar,
  Heading,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Button,
  Text,
  Link,
  Checkbox,
  HStack,
  Tooltip,
  Progress,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useAuth } from "../hooks/AuthProvider";
import { Link as RouterLink, useNavigate } from "react-router-dom"; 
import { FaUserAlt, FaLock, FaEnvelope } from "react-icons/fa";
import { createUser, loginAction } from "../api/users"; // Import loginAction
import Footer from "../components/Layout/Footer";


const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);
const CFaEnvelope = chakra(FaEnvelope);

const Signup = () => {
  const [input, setInput] = useState({
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
  });
  const auth = useAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(null);
  const [emailError, setEmailError] = useState(false); // Email validation error state
  const [isChecked, setIsChecked] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const evaluatePasswordStrength = (password) => {
    let score = 0;
    if (password.length >= 8) score += 1;
    if (/[A-Z]/.test(password)) score += 1;
    if (/[a-z]/.test(password)) score += 1;
    if (/[0-9]/.test(password)) score += 1;
    if (/[@$!%*?&#]/.test(password)) score += 1;
    return score;
  };

  const handlePasswordChange = (password) => {
    setInput((prev) => ({ ...prev, password }));
    setPasswordStrength(evaluatePasswordStrength(password));
  };

  const handleSubmitEvent = async (e) => {
    e.preventDefault();
    setError(null);
    setEmailError(false);
  
    // Validate email format
    if (!validateEmail(input.email)) {
      setEmailError(true); // Trigger email error
      setError("Invalid email format.");
      return;
    }
  
    // Check if terms and conditions are accepted
    if (!isChecked) {
      setError("You must accept the terms and conditions.");
      return;
    }
  
    // Check if passwords match
    if (input.password !== input.confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
  
    // Check password strength
    if (passwordStrength < 3) {
      setError("Your password is too weak. Please make it stronger.");
      return;
    }
  
    // Check if all required fields are filled
    if (input.email && input.username && input.password) {
      try {
        // Create user
        await createUser({
          email: input.email,
          username: input.username,
          password: input.password,
        });
  
        // Automatically log in the user
        const loginResponse = await loginAction({
          username: input.username,
          password: input.password,
        });
  
        alert("Signup and login successful!");
        console.log("Logged in user:", loginResponse);

        navigate("/base-home");
  
        // Optionally redirect to a different page
        // navigate("/dashboard"); // Uncomment if using React Router
      } catch (err) {
        // Handle API errors
        setError(err.response?.data?.message || "Signup failed. Please try again.");
        console.error("Error during signup or login:", err);
      }
    } else {
      setError("Please fill out all fields.");
    }
  };
  

  return (
    <Box>
      <Flex
        flexDirection="column"
        width="100wh"
        height="97vh"
        backgroundColor="xelerit.background"
        justifyContent="center"
        alignItems="center"
      >
        <Stack flexDir="column" mb="2" justifyContent="center" alignItems="center">
          <Avatar bg="xelerit.lightBlue" />
          <Heading color="xelerit.lightBlue">Sign Up for Xelerit</Heading>
          <Box minW={{ base: "90%", md: "468px" }}>
            <form onSubmit={handleSubmitEvent}>
              <Stack
                spacing={4}
                p="1rem"
                backgroundColor="vscode.sidebar"
                boxShadow="md"
              >
                {/* Email Field */}
                <FormControl isInvalid={emailError}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<CFaEnvelope color="vscode.accent" />}
                    />
                    <Input
                      type="email"
                      placeholder="Email"
                      value={input.email}
                      onChange={(e) => setInput({ ...input, email: e.target.value })}
                      required
                      color="vscode.foreground"
                      bg="vscode.sidebar"
                      _placeholder={{ color: "vscode.secondary" }}
                      borderColor={emailError ? "red.500" : "vscode.border"}
                    />
                  </InputGroup>
                  {emailError && (
                    <FormErrorMessage>Invalid email address.</FormErrorMessage>
                  )}
                </FormControl>

                {/* Username Field */}
                <FormControl>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<CFaUserAlt color="vscode.accent" />}
                    />
                    <Input
                      type="text"
                      placeholder="Username"
                      value={input.username}
                      onChange={(e) => setInput({ ...input, username: e.target.value })}
                      required
                      color="vscode.foreground"
                      bg="vscode.sidebar"
                      _placeholder={{ color: "vscode.secondary" }}
                    />
                  </InputGroup>
                </FormControl>

                {/* Password Field */}
                <FormControl>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<CFaLock color="vscode.accent" />}
                    />
                    <Input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      value={input.password}
                      onChange={(e) => handlePasswordChange(e.target.value)}
                      required
                      color="vscode.foreground"
                      bg="vscode.sidebar"
                      _placeholder={{ color: "vscode.secondary" }}
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={() => setShowPassword(!showPassword)}
                        color={"vscode.background"}
                      >
                        {showPassword ? "Hide" : "Show"}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <Progress
                    value={(passwordStrength / 5) * 100}
                    size="sm"
                    colorScheme={
                      passwordStrength < 3
                        ? "red"
                        : passwordStrength === 3
                        ? "yellow"
                        : "green"
                    }
                    mt={2}
                  />
                  <Tooltip
                    label="Use at least 8 characters, including uppercase, lowercase, numbers, and symbols."
                    bgColor="vscode.sidebar"
                    aria-label="Password strength tooltip"
                  >
                    <Text fontSize="sm" mt={2}>
                      Password Strength:{" "}
                      {["Weak", "Weak", "Fair", "Good", "Strong"][passwordStrength]}
                    </Text>
                  </Tooltip>
                </FormControl>

                {/* Confirm Password Field */}
                <FormControl>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<CFaLock color="vscode.accent" />}
                    />
                    <Input
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm Password"
                      value={input.confirmPassword}
                      onChange={(e) =>
                        setInput({ ...input, confirmPassword: e.target.value })
                      }
                      required
                      color="vscode.foreground"
                      bg="vscode.sidebar"
                      _placeholder={{ color: "vscode.secondary" }}
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        color={"vscode.background"}
                      >
                        {showConfirmPassword ? "Hide" : "Show"}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>

                {/* Terms and Conditions Checkbox */}
                <FormControl>
                  <HStack alignItems="center">
                    <Checkbox
                      isChecked={isChecked}
                      onChange={(e) => setIsChecked(e.target.checked)}
                      colorScheme="xelerit.lightBlue"
                    >
                      I accept the{" "}
                      <Link href="/terms" color="xelerit.lightBlue" isExternal>
                        Terms and Conditions
                      </Link>
                    </Checkbox>
                  </HStack>
                </FormControl>

                {error && <Text color="red.500" as="i">{error}</Text>}
                <Button
                  borderRadius={0}
                  type="submit"
                  variant="solid"
                  bg="xelerit.lightBlue"
                  color="white"
                  _hover={{ bg: "vscode.accent" }}
                  width="full"
                >
                  Sign Up
                </Button>
              </Stack>
            </form>
            <Box textAlign="center" mt={4}>
              <Text>
                Already have an account?{" "}
                <Link as={RouterLink} to="/login" color="xelerit.lightBlue">
                  Login
                </Link>
              </Text>
            </Box>
          </Box>
        </Stack>
      </Flex>
      <Footer />
    </Box>
  );
};

export default Signup;
