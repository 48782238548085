import React, { useState, useEffect } from 'react';
import { VStack, Tabs, TabList, TabPanels, Tab, TabPanel, Button, Input, HStack, Box, IconButton, Spinner } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import CodeMirror from "@uiw/react-codemirror";
import { vscodeDark } from '@uiw/codemirror-theme-vscode';

const CodeEditor = ({ codeFiles = [], onFilesChange, loading }) => {
  const [files, setFiles] = useState(codeFiles.map((file) => ({ ...file, isOpen: true })) || []);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);


  useEffect(() => {
    if (!loading && codeFiles.length > 0) {
      setFiles(codeFiles.map((file) => ({ ...file, isOpen: true })));
    }
  }, [codeFiles, loading]);

  const handleFileChange = (index, newContent) => {
    const updatedFiles = [...files];
    updatedFiles[index].content = newContent;
    setFiles(updatedFiles);
    if (onFilesChange) {
      onFilesChange(updatedFiles);
    }
  };

  const handleAddFile = () => {
    const newFile = { fileName: `NewProgram${files.length + 1}.erd`, content: "// New file content", isOpen: true };
    const updatedFiles = [...files, newFile];
    setFiles(updatedFiles);
    setCurrentFileIndex(updatedFiles.length - 1);
    if (onFilesChange) {
      onFilesChange(updatedFiles);
    }
  };

  const handleRenameFile = (index, newFileName) => {
    const updatedFiles = [...files];
    updatedFiles[index].fileName = newFileName;
    setFiles(updatedFiles);
    if (onFilesChange) {
      onFilesChange(updatedFiles);
    }
  };


  const handleOpenFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles[index].isOpen = true;
    setFiles(updatedFiles);
    setCurrentFileIndex(index);
    if (onFilesChange) {
      onFilesChange(updatedFiles);
    }
  };

  const openFiles = files.filter((file) => file.isOpen);
  const closedFiles = files.filter((file) => !file.isOpen);

  if (loading) {
    // Render a spinner or loading state
    return (
      <VStack justify="center" align="center" w="100%" h="100%">
        <Spinner size="xl" color="blue.500" />
        <Box mt={4}>Loading code files...</Box>
      </VStack>
    );
  }

  return (
    <VStack boxShadow="md" borderStyle="solid" spacing={8} p={4} overflow="auto" maxW="100%" h="100%">
      <Tabs
  onChange={(index) => setCurrentFileIndex(index)}
  index={openFiles.findIndex((file, i) => files.indexOf(file) === currentFileIndex)}
  w="100%"
  h="100%"
  overflow="hidden"
>
  {/* Add sticky behavior to TabList */}
  <Box position="sticky" top="0" zIndex="1"  overflowX="auto" maxW="100%" borderBottom="1px solid #e2e8f0">
    <TabList display="flex" flexWrap="nowrap">
      {openFiles.map((file, index) => (
        <HStack key={index} spacing={1}>
          <Tab overflow="auto" textOverflow="ellipsis" whiteSpace="nowrap" maxW="200px">
            {file.fileName}
          </Tab>
        </HStack>
      ))}
      <Button height="30px" width="100px" onClick={handleAddFile} ml={2}>
        + Add File
      </Button>
    </TabList>
  </Box>

  <TabPanels w="100%" h="100%" mt={4}>
    {openFiles.map((file, index) => (
      <TabPanel key={index}  w="100%" h="100%" overflow="hidden">
        <VStack w="100%" h="80%" align="stretch">
          <HStack mb={2} spacing={4} >
            <Input
              value={file.fileName}
              onChange={(e) => handleRenameFile(files.indexOf(file), e.target.value)}
              size="sm"
              placeholder="File Name"
            />
          </HStack>
          <Box flex="1" width="100%" h="100%">
            <CodeMirror
              value={file.content}
              onChange={(value) => handleFileChange(files.indexOf(file), value)}
              theme={vscodeDark}
              height="100%"
              maxWidth="100%"
              basicSetup={{
                foldGutter: false,
                dropCursor: false,
                allowMultipleSelections: false,
                indentOnInput: false,
              }}
            />
          </Box>
        </VStack>
      </TabPanel>
    ))}
  </TabPanels>
</Tabs>
    </VStack>
  );
};

export default CodeEditor;



