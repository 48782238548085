import React, { useState } from "react";
import { Box, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Button, Textarea, useDisclosure, Select } from "@chakra-ui/react";
import { sendFeedback } from "../api/feedback";
const FeedbackComponent = ({ isOpen, onClose, userId }) => {
    const [feedbackText, setFeedbackText] = useState("");
    const [selectedFeature, setSelectedFeature] = useState("");

    const handleFeedbackSubmit = async () => {
        if (!selectedFeature || feedbackText.trim() === "") {
          alert("Please fill in all fields.");
          return;
        }
        console.log("Submitting feedback:", selectedFeature, feedbackText);
        console.log("User ID:", userId);
    
        const feedbackData = {
          feature: selectedFeature,
          feedback: feedbackText,
          user_id: userId, // Include userId in the payload
        };
    
        try {
          
          const response = await sendFeedback(feedbackData); // Call the API to submit feedback
        } catch (error) {
          console.error("Error submitting feedback:", error);
          alert("An error occurred. Please try again later.");
        } finally {
            setFeedbackText(""); // Reset feedback text
            setSelectedFeature(""); // Reset selected feature
            onClose(); // Close the modal
          
        }
      };

    return (
        <Modal isOpen={isOpen} onClose={() => {}} isCentered={false}>
            <ModalOverlay bg="blackAlpha.600" backdropFilter="blur(5px)" />
            <ModalContent position="fixed" bottom="0" right="10" maxWidth="400px" bg="vscode.sidebar">
                <ModalHeader>We Value Your Feedback!</ModalHeader>
                <ModalCloseButton onClick={() => {}} />
                <ModalBody>
                    <Select placeholder="Select feature" mb={4} onChange={(e) => setSelectedFeature(e.target.value)}>
                        <option value="feature1">Chat</option>
                        <option value="feature2">Flowdiagram</option>
                        <option value="feature3">Translator</option>
                    </Select>
                    <Textarea placeholder="Share your thoughts..." mb={4} value={feedbackText} onChange={(e) => setFeedbackText(e.target.value)} />
                    <Button bgColor={"xelerit.lightBlue"} color={"white"} onClick={handleFeedbackSubmit}>
                        Submit Feedback
                    </Button>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default FeedbackComponent;
