import apiClient from './apiClient'; // Import the shared API client


// fetch all the projects
export const getAllProjects = async () => {
    return apiClient.get('/projects');
    }
// fetch a single project by ID
export const getProjectById = async (projectId) => {
    return apiClient.get(`/projects/${projectId}`);
    }
// create a new project
export const createProject = async (data) => {
    return apiClient.post('/projects', data);
    }
// update a project by ID
export const updateProject = async (projectId, data) => {
    return apiClient.put(`/projects/${projectId}`, data);
    }
// delete a project by ID
export const deleteProject = async (projectId) => {
    return apiClient.delete(`/projects/${projectId}`);
    }

// Fetch project details with flow diagram and nodes
export const getProjectDetails = async (projectId) => {
    return apiClient.get(`/projects/${projectId}/details`);
};
    